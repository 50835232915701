var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plugins"},[_c('div',{staticClass:"imgbox",style:({
			'paddingTop':_vm.plugins_data.style.padding_top/2+'px',
			'paddingBottom':_vm.plugins_data.style.padding_bottom/2+'px',
			'paddingLeft':_vm.plugins_data.style.padding_left/2+'px',
			'paddingRight':_vm.plugins_data.style.padding_left/2+'px'
		})},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"contentbox",style:({
					'height':_vm.plugins_data.video.type==1?'197px':_vm.plugins_data.video.type==2?(_vm.plugins_data.video.styleType==1?'351px':'115px'):'263px',
					'borderRadius':_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px',
					})},[_c('video',{staticClass:"content",style:({'borderRadius':_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px'}),attrs:{"id":_vm.video_id,"src":_vm.plugins_data.video.src},on:{"click":_vm.playVideo}},[_vm._v(" 当前浏览器不支持 video直接播放 ")]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.plugins_data.video.image&&!_vm.is_pay),expression:"plugins_data.video.image&&!is_pay"}],staticClass:"img",attrs:{"src":_vm.plugins_data.video.image}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_pay),expression:"!is_pay"}],staticClass:"modal",style:({
						'borderRadius':_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px',
					})},[_c('i',{staticClass:"playicon el-icon-video-play",on:{"click":_vm.playVideo}})])]),(_vm.plugins_data.video.styleType==2)?_c('div',{staticClass:"textbox"},[_c('div',{staticClass:"title",style:({'color':_vm.plugins_data.video.titleColor})},[_vm._v(_vm._s(_vm.plugins_data.video.title||'请输入视频标题'))]),_c('div',{staticClass:"desc",style:({'color':_vm.plugins_data.video.descColor})},[_vm._v(_vm._s(_vm.plugins_data.video.desc||'请输入视频描述'))])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }