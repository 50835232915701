<template>
  <div
    id="box"
    :style="{
      background: plugins_data.style.background,
      justifyContent: plugins_data.style.text_style,
      borderRadius:
        plugins_data.style.border_radius_top / 2 +
        'px ' +
        plugins_data.style.border_radius_top / 2 +
        'px ' +
        plugins_data.style.border_radius_bottom / 2 +
        'px ' +
        plugins_data.style.border_radius_bottom / 2 +
        'px',
    }"
  >
    <div class="tabs">
      <div class="active">选择</div>
      <div>选择</div>
    </div>
    <div class="img">
      <img src="../../imgs/goodplugins/1.jpg" alt="" />
    </div>
    <div class="content">
      <div class="title">
        <span>标题标签</span>
        <span>这里是商品标题</span>
      </div>
      <div class="title2">这里是商品副标题</div>
      <div class="price">
        <span>￥20</span>
        <span>购买</span>
      </div>
    </div>
    <!-- <div class="notice">
      <img src="../imgs/notice/1.png" alt="" />
      <span style="margin: 0 10px">|</span>
      <span>由于天气原因，近期物流会推迟...</span>
    </div> -->
    <div
      :style="{
        paddingLeft: plugins_data.style.padding_left / 2 + 'px',
        paddingRight: plugins_data.style.padding_left / 2 + 'px',
        paddingTop: plugins_data.style.padding_top / 2 + 'px',
        paddingBottom: plugins_data.style.padding_bottom / 2 + 'px',
      }"
      class="titlebox"
    >
      <div class="title">
        <div class="text" :style="{ color: plugins_data.style.color }">
          <!-- {{ plugins_data.text || "请输入标题栏内容" }} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eltips } from "@/util/util.js";
export default {
  name: "titles",
  data() {
    return {
      a1: "",
    };
  },
  props: {
    plugins_data: { type: Object },
  },
  created() {
    console.log("11");
  },
  methods: {},
};
</script>

<style scoped lang="less">
.title {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 30px;
  padding: 0 8px;
  .text {
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  padding: 10px 80px;
  color: #666666;
  .active {
    color: rgb(239, 79, 79);
    font-weight: 600;
    position: relative;
  }
  // 底部绿线
  .active::after {
    content: "";
    display: block;
    width: 180%;
    height: 4px;
    background: #04e382;
    position: absolute;
    top: 25px;
    bottom: 0;
    left: -10px;
  }
}

.img {
  padding: 10px;
  img {
    width: 100%;
  }
}

.title {
  span:nth-child(1) {
    padding: 0 4px;
    line-height: 18px;
    font-size: 10px;
    font-weight: 400;
    border-radius: 2px;
    margin-right: 2px;
    display: inline-block;
    color: #fff;
    background: rgb(255, 47, 70);
  }
  span:nth-child(2) {
    line-height: 30px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    margin-left: 3px;
  }
}
.title2 {
  color: rgb(153, 156, 167);
  margin: 0 10px;
}
.price {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  span:nth-child(1) {
    color: rgb(253, 70, 62);
  }
  span:nth-child(2) {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    padding: 5px 10px;
    background: rgb(253, 70, 62);
    border-radius: 4px;
  }
}
.notice {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 22px;
  }
}
</style>
