<template>
	<div id="search" :style="{
		'paddingLeft':plugins_data.style.padding_left/2+'px',
		'paddingRight':plugins_data.style.padding_left/2+'px',
		'paddingTop':plugins_data.style.padding_top/2+'px',
		'paddingBottom':plugins_data.style.padding_bottom/2+'px',
	}">
		<div class="searchbox" 
		:style="{
			'justifyContent':plugins_data.style.text_style,
			'backgroundColor':plugins_data.style.background,
			'borderRadius':plugins_data.style.border_radius/2+'px'
		}">
			<i class="el-icon-search" :style="{'color':plugins_data.style.color}"></i>
			<div style="margin-left: 4px;" :style="{'color':plugins_data.style.color}">{{plugins_data.placeholder}}</div>
		</div>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
export default {
	name: 'search',
	data() {
		return {
			
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	created() {
		
	},
	methods:{
		
	}
};
</script>



<style scoped lang="less">
	.searchbox{
		height: 30px;
		display: flex;
		align-items: center;
		padding: 0 8px;
	}
</style>
