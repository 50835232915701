<template>
  <div id="pluginswiper">
    <div
      class="swiper"
      :style="{
        paddingTop: plugins_data.style.padding_top / 2 + 'px',
        paddingBottom: plugins_data.style.padding_bottom / 2 + 'px',
      }"
    >
      <div class="scroll-view_H">
        <div
          class="scroll-view-item_H"
          v-if="plugins_data.coupon.length == 0"
          :style="{
            paddingLeft: plugins_data.style.padding_left + 'px',
            paddingRight: plugins_data.style.padding_left + 'px',
          }"
        >
          <div
            class="item-content"
            :style="{
              background: plugins_data.style.backgroundColor,
              borderRadius:
                plugins_data.style.border_radius_top / 2 +
                'px ' +
                plugins_data.style.border_radius_top / 2 +
                'px ' +
                plugins_data.style.border_radius_bottom / 2 +
                'px ' +
                plugins_data.style.border_radius_bottom / 2 +
                'px',
            }"
          >
            <div class="item-content-left">
              <div
                class="item-left-bg"
                :style="{
                  background: plugins_data.style.insetBg,
                  color: plugins_data.style.backgroundColor,
                }"
              >
                <i
                  class="tem-bgcoin"
                  :style="{ background: plugins_data.style.backgroundColor }"
                ></i>
                <!-- <div class="coupon-money">
									<div class="coin">{{item.type == 1?"折":"￥"}}</div>
									<div>{{item.discount}}</div>
								</div> -->
                <div
                  class="coupon-desc"
                  :style="{ background: plugins_data.style.insetBg }"
                >
                  <div class="coupon-desc-content">
                    优惠券类型
                    <div class="coupon-desc-text">优惠券功能</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-content-right">
              <div
                class="item-right-click"
                :style="{
                  color: plugins_data.style.insetBg,
                  border: '3px solid ' + plugins_data.style.insetBg + '',
                }"
              >
                点击领取
              </div>
            </div>
          </div>
        </div>
        <div
          class="scroll-view-item_H"
          :style="{
            paddingLeft: plugins_data.style.padding_left + 'px',
            paddingRight: plugins_data.style.padding_left + 'px',
          }"
          v-for="(item, index) in plugins_data.coupon"
          :key="index"
        >
          <div
            class="item-content"
            :style="{
              background: plugins_data.style.backgroundColor,
              borderRadius:
                plugins_data.style.border_radius_top / 2 +
                'px ' +
                plugins_data.style.border_radius_top / 2 +
                'px ' +
                plugins_data.style.border_radius_bottom / 2 +
                'px ' +
                plugins_data.style.border_radius_bottom / 2 +
                'px',
            }"
          >
            <div class="item-content-left">
              <div
                class="item-left-bg"
                :style="{
                  background: plugins_data.style.insetBg,
                  color: plugins_data.style.backgroundColor,
                }"
              >
                <i
                  class="tem-bgcoin"
                  :style="{ background: plugins_data.style.backgroundColor }"
                ></i>
                <!-- <div class="coupon-money">
									<div class="coin">{{item.type == 1?"折":"￥"}}</div>
									<div>{{item.discount}}</div>
								</div> -->
                <div
                  class="coupon-desc"
                  :style="{ background: plugins_data.style.insetBg }"
                >
                  <div class="coupon-desc-content">
                    {{ item.type == 1 ? "折扣券" : "满减券" }}
                    <div class="coupon-desc-text">
                      <template v-if="item.type == 1">
                        满{{ parseFloat(item.full) }}元享{{
                          parseFloat(item.discount)
                        }}折
                      </template>
                      <template v-else>
                        满{{ parseFloat(item.full) }}元减{{
                          parseFloat(item.discount)
                        }}元
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-content-right">
              <div
                class="item-right-click"
                :style="{
                  color: plugins_data.style.insetBg,
                  border: '3px solid ' + plugins_data.style.insetBg + '',
                }"
              >
                点击领取
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "swiperView",
  data() {
    return {};
  },
  props: {
    plugins_data: { type: Object },
  },
  watch: {
    plugins_data: {
      //监听的对象
      deep: true, //深度监听设置为 true
      handler: function (newV, oldV) {
        console.log("watch中：", newV);
      },
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.scroll-view_H {
  white-space: nowrap;
  width: 100%;
  overflow-x: auto;
}

.scroll-view-item_H {
  display: inline-block;
  // width: 100%;
  // text-align: center;
  font-size: 36px;

  .item-content {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    background: #d4281c;
    height: 80px;
    .item-content-left {
      padding: 10px 0 10px 10px;
      height: 100%;

      .tem-bgcoin {
        width: 35px;
        height: 35px;
        background: #d4281c;
        border-radius: 50%;
        position: absolute;
        left: -25px;
      }

      .item-left-bg {
        position: relative;
        background: #ffd8b2;
        color: #e04030;
        height: 100%;
        // padding: 0 150px 0 40px;
        padding: 0 0 0 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .coupon-money {
          font-size: 26px;
          display: flex;
          align-items: flex-start;
          .coin {
            line-height: 30px;
            font-size: 12px;
            font-weight: 400;
            margin-right: 10px;
          }
        }

        .coupon-desc {
          // position: absolute;
          margin-right: -25px;
          // margin-left: 10px;
          height: calc(100% - 10px);
          background: #ffd8b2;
          text-align: center;
          font-size: 14px;
          display: flex;
          padding-right: 20px;
          border-radius: 0 50px 50px 0;
          align-items: center;
          // box-shadow: -1px 0 4px 0 #DF8C62 inset;
        }

        // .coupon-desc-text {}
      }
    }

    .item-content-right {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 10px 0 30px;

      .item-right-click {
        // background: #e03928;
        display: flex;
        text-align: center;
        align-items: center;
        padding: 0 5px;
        white-space: normal;
        box-sizing: border-box;
        width: 50px;
        height: 50px;
        font-size: 12px;
        border-radius: 50%;
        color: #ffebd5;
        border: 5px solid #ffead3;
      }
    }
  }
}
</style>
