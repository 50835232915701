<template>
	<div class="plugins">
		<div>
			<div class="headsty">组件样式</div>
			<div class="box">
				<div class="label">视频尺寸:</div>
				<el-radio-group v-model="plugins_data.video.type">
				    <el-radio :disabled="plugins_data.video.styleType!=1" :label="1">16:9</el-radio>
				    <el-radio :label="2">1:1</el-radio>
				    <el-radio :disabled="plugins_data.video.styleType!=1" :label="3">4:3</el-radio>
				</el-radio-group>
				
			</div>
			<div class="box">
				<div class="label">视频样式:</div>
				<el-radio-group v-model="plugins_data.video.styleType" @change="changeStyleType">
				    <el-radio :label="1">纯视频</el-radio>
				    <el-radio :label="2">图文列表</el-radio>
				</el-radio-group>
			</div>
		</div>
		<div>
			<div class="headsty">内容</div>
			<div class="box">
				<div class="label">封面图:</div>
				<div class="imgbox" @click="imgShow=true">
					<img v-if="plugins_data.video.image" style="width: 100%; height: 100%;object-fit:cover;" :src="plugins_data.video.image">
					<i v-if="!plugins_data.video.image" class="icon el-icon-plus"></i>
					<span v-if="!plugins_data.video.image" class="text">添加图片</span>
				</div>
			</div>
			<div class="box">
				<div class="label">视频链接:</div>
				<el-input placeholder="请选择视频" disabled v-model="plugins_data.video.name">
				    <el-button slot="append" @click="videoShow=true">选择视频</el-button>
				</el-input>
			</div>
			<div class="box" v-if="plugins_data.video.styleType==2">
				<div class="label">视频标题:</div>
				<el-input v-model="plugins_data.video.title" placeholder="请输入视频标题"></el-input>
			</div>
			<div class="box" v-if="plugins_data.video.styleType==2">
				<div class="label">标题颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.video.titleColor"></el-color-picker>
				<el-input v-model="plugins_data.video.titleColor" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.video.titleColor = '#333333'">重置</el-button>
			</div>
			<div class="box" v-if="plugins_data.video.styleType==2">
				<div class="label">描述文字:</div>
				<el-input type="textarea" v-model="plugins_data.video.desc" placeholder="请输入视频描述"></el-input>
			</div>
			<div class="box" v-if="plugins_data.video.styleType==2">
				<div class="label">描述颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.video.descColor"></el-color-picker>
				<el-input v-model="plugins_data.video.descColor" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.video.descColor = '#999999'">重置</el-button>
			</div>
		</div>
		<div>
			<div class="headsty">内容样式</div>
			<div class="box">
				<div class="label">上边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_top" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_top">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">左右边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_left" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_left">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_bottom" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_top">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
		</div>
		
		<xsk-manage :managehide.sync="imgShow" @confirm="pickimg"></xsk-manage>
		<xsk-video-manage :managehide.sync="videoShow" @confirm="pickVideo"></xsk-video-manage>
	</div>
</template>

<script>
export default {
	name: 'videoData',
	data() {
		return {
			imgShow:false,
			videoShow:false,
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	created() {
		
	},
	methods:{
		pickimg(e){
			if(e.imgobjs.length>0){
				console.log(e)
				this.plugins_data.video.image = e.imgobjs[0].qiniu_url
			}
		},
		pickVideo(e){
			if(e){
				this.plugins_data.video.src = e.qiniu_url
				this.plugins_data.video.name = e.original_name
			}
		},
		changeStyleType(e){
			if(e==2) return this.plugins_data.video.type = 2;
		}
	}
};
</script>

<style scoped lang="less">
@import "../../style/pluginsCommon.css";

.imgbox{
	width: 100px;
	height: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px dashed #E9EDEF;
	cursor: pointer;
	.icon{
		font-size: 42px;
		color: #2d8cf0;
	}
	.text{
		margin-top: 8px;
		color: #B8B9BD;
		font-size: 12px;
	}
}
</style>
