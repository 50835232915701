export let shoppingLinkArr = [
	{
		title:'商城页面',
		child:[
			{id:1,name:'商城首页',path:'/pages/index/index'},
			{id:2,name:'分类导航',path:'/pages/index/index'},
			{id:3,name:'全部商品',path:'/pages/index/index'},
			{id:4,name:'购物车',path:'/pages/index/index'},
		]
	},
	{
		title:'活动',
		child:[
			{id:111,name:'拼团',path:'/activity/pages/dough'},
			{id:112,name:'砍价',path:'/activity/pages/bargain'},
			// {id:112,name:'分类导航',path:'/pages/index/index'},
			// {id:113,name:'全部商品',path:'/pages/index/index'},
			// {id:114,name:'购物车',path:'/pages/index/index'},
		]
	},
	{
		title:'会员中心',
		child:[
			{id:5,name:'会员中心',path:'/pages/index/index'},
			{id:6,name:'会员等级说明',path:'/pages/index/index'},
			{id:7,name:'我的订单(全部)',path:'/pages/index/index'},
			{id:8,name:'待付款订单',path:'/pages/index/index'},
			{id:9,name:'待发货订单',path:'/pages/index/index'},
			{id:10,name:'待收货订单',path:'/pages/index/index'},
			{id:11,name:'已完成订单',path:'/pages/index/index'},
			{id:12,name:'维权订单',path:'/pages/index/index'},
			{id:13,name:'我的收藏',path:'/pages/index/index'},
			{id:14,name:'我的足迹',path:'/pages/index/index'},
			{id:15,name:'会员充值',path:'/pages/index/index'},
			{id:16,name:'余额明细',path:'/pages/index/index'},
			{id:17,name:'积分明细',path:'/pages/index/index'},
			{id:18,name:'余额提现',path:'/pages/index/index'},
			{id:19,name:'我的资料',path:'/pages/index/index'},
			{id:20,name:'积分排行',path:'/pages/index/index'},
			{id:21,name:'消费排行',path:'/pages/index/index'},
			{id:22,name:'收货地址管理',path:'/pages/index/index'},
			{id:23,name:'红包领取明细',path:'/pages/index/index'},
			{id:124,name:'付费会员',path:'/vip/pages/consume'},
			{id:125,name:'储值卡',path:'/personal/pages/card'},
			{id:126,name:'新人专享',path:'/order/pages/newcomers'},
			{id:127,name:'秒杀抢购',path:'/order/pages/seckill'},
			{id:128,name:'特价商品',path:'/order/pages/special'},
		]
	},
	{
		title:'分销',
		child:[
			{id:24,name:'分销中心',path:'/pages/index/index'},
			{id:25,name:'等级说明',path:'/pages/index/index'},
			{id:26,name:'分销订单',path:'/pages/index/index'},
			{id:27,name:'分销佣金',path:'/pages/index/index'},
			{id:28,name:'我的下线',path:'/pages/index/index'},
			{id:29,name:'提现明细',path:'/pages/index/index'},
			{id:30,name:'佣金排名',path:'/pages/index/index'},
		]
	},
	{
		title:'多商户会员中心',
		child:[
			{id:31,name:'商户入驻',path:'/pages/index/index'},
			{id:32,name:'推广管理',path:'/pages/index/index'},
		]
	},
	{
		title:'客服',
		child:[
			{id:33,name:'客服系统',path:'/pages/index/index'},
		]
	},
	{
		title:'优惠券',
		child:[
			{id:34,name:'领取优惠券',path:'/activity/pages/receiveCoupon'},
			{id:35,name:'我的优惠券',path:'/pages/index/index'},
		]
	},
]