<template>
	<div id="xskUploadQn">
		<el-dialog title="上传视频" :visible.sync="uploadshow" width="700px" :modal-append-to-body='false'  :before-close="handleClose" :close-on-click-modal="false">
			<div>
				<div class="qnbox" v-loading="videoloading">
					<div class="uploadbox">
						<i class="icon el-icon-plus"></i>
						<div class="text">点击选择视频</div>
						<input class="addinput" accept="video/*" type="file" :multiple="true" @change="pickvideo" />
					</div>
					<video id="videoPlay" v-if="videourl" class="video" :src="videourl" controls></video>
				</div>
				<div class="progress" v-if="percent>0">
					<el-progress :text-inside="true" :stroke-width="20" :percentage="percent" status="success"></el-progress>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取消</el-button>
				<el-button type="primary" @click="confirm">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import * as qiniu from 'qiniu-js';
import { eltips } from '@/util/util.js';
export default {
	name: 'xskUploadQn',
	data() {
		return {
			videourl: '', //视频地址
			percent:0, //上传进度
			duration:'',//视频时长
			videoloading:false,
			subscription:'',//七牛云上传对象
		};
	},
	props: {
		//控制插件显示
		uploadshow: {
			type: Boolean,
			default: false
		},
		//传入视频连接
		urls:{
			type: String,
			default: ''
		},
		//视频时长
		durations:{
			type: Number,
			default: 0
		},
		//大小限制
		size:{
			type:Number,
			default:0,
		},
	},
	created() {},
	watch:{
		urls:{
		    handler(val){
			  this.videourl = JSON.parse(JSON.stringify(val)) 
		    },
		    immediate: true,
			deep: true,
		},
		durations:{
		    handler(val){
			  if(val!=0){
				this.duration = JSON.parse(JSON.stringify(val))  
			  }
		    },
		    immediate: true,
			deep: true,
	    }
	},
	methods: {
		//如果有视频 停止现有视频播放
		videostop(){
			if(this.videourl){
				let video = document.getElementById('videoPlay');
				if(!video.paused){
					video.pause()
				}
			}
		},
		//关闭前判断是否有正在上传的视频
		handleClose(){
			if(this.percent>0&&this.percent<100){
				this.$confirm('还有正在上传的视频, 是否中断并退出?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  this.subscription.unsubscribe()
				  this.closeplugin()
				}).catch(() => {});
			}else{
				this.closeplugin()
			}
		},
		//关闭组件
		closeplugin() {
			this.videostop()
			this.percent = 0
			this.videoloading = false
			
			this.$emit('update:uploadshow', false);
		},
		//确认事件
		confirm(){
			this.closeplugin()
			let obj = {
				videourl:this.videourl,
				duration:this.duration
			}
			this.$emit('confirm',obj)
		},
		//选择视频
		async pickvideo(e) {
			let that = this;
			let file = e.target.files[0];
			if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1) {
				eltips('请上传支持的视频格式（mp4，flv，avi，wmv，rmvb，mov）', 'error');
				return false;
			}
			
			if(this.size!=0&&file.size/1024/1024>this.size){
				eltips('视频大小不得超过'+this.size+'M', 'error');
				return false;
			}
			//获取视频时长
			let url = URL.createObjectURL(file);
			let audioElement = new Audio(url);
			let result;
			audioElement.addEventListener("loadedmetadata", function() {
			   // 视频时长值的获取要等到这个匿名函数执行完毕才产生
			   result = audioElement.duration; //得到时长为秒，小数，182.36
			   that.duration = parseInt(result)
			});
			
			//设置视频key 时间戳+文件名
			let key = new Date().getTime() + file.name;
			let token;
			
			//获取七牛token
			await this.axios.post('/api/upload/get_qiniu_token',{type:'video'}).then(res => {
				token = res.data;
			});
			
			//设置七牛云参数 config
			let config = {
				concurrentRequestLimit: 2
			};
			
			//设置七牛云参数 putExtra
			let putExtra = {
				//原文件名
				fname: '',
				//用来放置自定义变量
				params: {},
				//限制上传文件类型
				mimeType: null
			};

			//设置七牛云上传回调事件 observer
			let observer = {
				//上传过程的监听函数 result参数带有total字段的 object，包含loaded、total、percent三个属性)
				next(result) {
					that.percent = parseInt(result.total.percent)
				},
				//上传失败回调
				error(err) {
					console.log('七牛云上传失败',err)
					that.videoloading = false
				},
				// 上传完成回调
				complete(res) {
					that.videoloading = false
					that.videourl = 'https://qiniuyun.xcooteam.cn/'+res.key
				}
			};

			//创建七牛云上传
			let observable = qiniu.upload(file, key, token, putExtra, config);
			this.videourl = ''
			this.percent = 1
			this.videoloading = true
			
			//触发上传并执行上传回调
			this.subscription = observable.subscribe(observer)
		},
	}
};
</script>

<style>
/* element 样式修改 */
#xskUploadQn .el-dialog__footer,
#xskUploadQn .el-dialog__body,
#xskUploadQn .el-dialog__header {
	padding: 14px 16px !important;
}
#xskUploadQn .el-dialog__headerbtn {
	top: 15px;
}
#xskUploadQn .el-dialog__header {
	font-weight: bold;
	border-bottom: 1px solid #e9edef;
}
#xskUploadQn .el-dialog__body {
	padding: 0 !important;
	border-bottom: 1px solid #e9edef;
}
#xskUploadQn .el-pagination--small .el-pagination__editor.el-input .el-input__inner {
	height: 22px !important;
	line-height: 22px !important;
}
</style>

<style lang="less">
#xskUploadQn {
	.qnbox {
		width: 100%;
		padding: 20px;
		background: #ffffff;
		display: flex;
		.video {
			width: 280;
			height: 120px;
			margin-left: 20px;
		}
		.uploadbox {
			width: 120px;
			height: 120px;
			border: 1px dashed #999999;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			border-radius: 4px;
			.icon {
				color: #1e92ff;
				font-size: 30px;
			}
			.text {
				margin-top: 6px;
				font-size: 14px;
				color: #666666;
			}
			.addinput {
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
			}
		}
	}
	.progress{
		padding:6px 20px;
	}
}
</style>
