<template>
	<div id="titles">
		<div :style="{
			'paddingLeft':plugins_data.style.padding_left/2+'px',
			'paddingRight':plugins_data.style.padding_left/2+'px',
			'paddingTop':plugins_data.style.padding_top/2+'px',
			'paddingBottom':plugins_data.style.padding_bottom/2+'px',
		}" class="titlebox">
			<div :style="{ 
				'background':plugins_data.style.background,
				'justifyContent':plugins_data.style.text_style,
				'borderRadius':plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_bottom/2+'px '+plugins_data.style.border_radius_bottom/2+'px',
				}"
				class="title"
			>
				<div class="text" :style="{'color':plugins_data.style.color}">{{plugins_data.text||'请输入标题栏内容'}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
export default {
	name: 'titles',
	data() {
		return {
			
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	created() {
		
	},
	methods:{
		
	}
};
</script>

<style scoped lang="less">
	.title{
		position: relative;
		display: flex;
		align-items: center;
		line-height:30px;
		padding: 0 8px;
		.text{
			
		}
	}
</style>
