var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    background: _vm.plugins_data.style.background,
    justifyContent: _vm.plugins_data.style.text_style,
    borderRadius:
      _vm.plugins_data.style.border_radius_top / 2 +
      'px ' +
      _vm.plugins_data.style.border_radius_top / 2 +
      'px ' +
      _vm.plugins_data.style.border_radius_bottom / 2 +
      'px ' +
      _vm.plugins_data.style.border_radius_bottom / 2 +
      'px',
  }),attrs:{"id":"box"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"titlebox",style:({
      paddingLeft: _vm.plugins_data.style.padding_left / 2 + 'px',
      paddingRight: _vm.plugins_data.style.padding_left / 2 + 'px',
      paddingTop: _vm.plugins_data.style.padding_top / 2 + 'px',
      paddingBottom: _vm.plugins_data.style.padding_bottom / 2 + 'px',
    })},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"text",style:({ color: _vm.plugins_data.style.color })})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs"},[_c('div',{staticClass:"active"},[_vm._v("选择")]),_c('div',[_vm._v("选择")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../imgs/goodplugins/1.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("标题标签")]),_c('span',[_vm._v("这里是商品标题")])]),_c('div',{staticClass:"title2"},[_vm._v("这里是商品副标题")]),_c('div',{staticClass:"price"},[_c('span',[_vm._v("￥20")]),_c('span',[_vm._v("购买")])])])
}]

export { render, staticRenderFns }