var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
	'paddingLeft':_vm.plugins_data.style.padding_left/2+'px',
	'paddingRight':_vm.plugins_data.style.padding_left/2+'px',
	'paddingTop':_vm.plugins_data.style.padding_top/2+'px',
	'paddingBottom':_vm.plugins_data.style.padding_bottom/2+'px',
}),attrs:{"id":"search"}},[_c('div',{staticClass:"searchbox",style:({
		'justifyContent':_vm.plugins_data.style.text_style,
		'backgroundColor':_vm.plugins_data.style.background,
		'borderRadius':_vm.plugins_data.style.border_radius/2+'px'
	})},[_c('i',{staticClass:"el-icon-search",style:({'color':_vm.plugins_data.style.color})}),_c('div',{staticStyle:{"margin-left":"4px"},style:({'color':_vm.plugins_data.style.color})},[_vm._v(_vm._s(_vm.plugins_data.placeholder))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }