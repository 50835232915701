<template>
	<div id="toppint-search" :style="{
		'paddingLeft':plugins_data.style.padding_left/2+'px',
		'paddingRight':plugins_data.style.padding_left/2+'px',
		'paddingTop':plugins_data.style.padding_top/2+'px',
		'paddingBottom':plugins_data.style.padding_bottom/2+'px',
		'backgroundColor':plugins_data.style.background,
	}">
		<div class="searchbox" :style="{
			'justifyContent':plugins_data.style.text_style,
			'backgroundColor':plugins_data.style.inputBackground,
			'borderRadius':plugins_data.style.border_radius/2+'px'
		}">
			<i class="el-icon-search" :style="{'color':plugins_data.style.color}"></i>
			<div style="margin-left: 4px;" :style="{'color':plugins_data.style.color}">{{plugins_data.placeholder}}
			</div>
			<div class="search-btn" :style="{
				'color':plugins_data.style.btnColor,
				'borderRadius':plugins_data.style.border_radius/2-2+'px',
				'backgroundColor':plugins_data.style.background,
				}">搜索</div>
		</div>
	</div>
</template>

<script>
	import {
		eltips
	} from '@/util/util.js';
	export default {
		name: 'search',
		data() {
			return {

			};
		},
		props: {
			plugins_data: {
				type: Object
			}
		},
		created() {

		},
		methods: {

		}
	};
</script>



<style scoped lang="less">
	.searchbox {
		height: 36px;
		padding: 0 8px;
		display: flex;
		align-items: center;
		position: relative;

		.search-btn {
			position: absolute;
			top: 2px;
			right: 2px;
			bottom: 2px;
			padding: 6px 15px;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>