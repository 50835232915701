var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"titles"}},[_c('div',{staticClass:"titlebox",style:({
		'paddingLeft':_vm.plugins_data.style.padding_left/2+'px',
		'paddingRight':_vm.plugins_data.style.padding_left/2+'px',
		'paddingTop':_vm.plugins_data.style.padding_top/2+'px',
		'paddingBottom':_vm.plugins_data.style.padding_bottom/2+'px',
	})},[_c('div',{staticClass:"title",style:({ 
			'background':_vm.plugins_data.style.background,
			'justifyContent':_vm.plugins_data.style.text_style,
			'borderRadius':_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px',
			})},[_c('div',{staticClass:"text",style:({'color':_vm.plugins_data.style.color})},[_vm._v(_vm._s(_vm.plugins_data.text||'请输入标题栏内容'))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }