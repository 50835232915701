<template>
	<div class="plugins">
		<div>
			<div class="headsty">内容样式</div>
			<div class="box">
				<div class="label">魔方布局:</div>
				<!-- <el-radio-group v-model="plugins_data.type" size="mini">
					<el-radio-button :label="1" size="mini">一行两个</el-radio-button>
					<el-radio-button :label="2" size="mini">一行三个</el-radio-button>
					<el-radio-button :label="3" size="mini">左一右二</el-radio-button>
					<el-radio-button :label="4" size="mini">上一下二</el-radio-button>
					<el-radio-button :label="5" size="mini">左一右三</el-radio-button>
				</el-radio-group> -->
				<div class="btnlist">
					<div class="btn" :class="{'active':plugins_data.type==1}" @click="changeType(1)">一行两个</div>
					<div class="btn" :class="{'active':plugins_data.type==2}" @click="changeType(2)">一行三个</div>
					<div class="btn" :class="{'active':plugins_data.type==3}" @click="changeType(3)">左一右二</div>
					<div class="btn" :class="{'active':plugins_data.type==4}" @click="changeType(4)">上一下二</div>
					<div class="btn" :class="{'active':plugins_data.type==5}" @click="changeType(5)">左一右三</div>
				</div>
			</div>
			<div class="box">
				<div class="label"></div>
				<div style="flex: 1;">
					<div class="styleList" v-if="plugins_data.type==1">
						<div class="magicbox" :class="{'active':index==0}" @click="changeIndex(0)">
							<el-image v-if="plugins_data.data[0].image" class="img" :src="plugins_data.data[0].image" fit="cover"></el-image>
							<div v-else class="textStyle">宽度375PX</div>
						</div>
						<div class="magicbox" :class="{'active':index==1}" @click="changeIndex(1)">
							<el-image v-if="plugins_data.data[1].image" class="img" :src="plugins_data.data[1].image" fit="cover"></el-image>
							<div v-else class="textStyle">宽度375PX</div>
						</div>
					</div>
					
					<div class="styleList" v-if="plugins_data.type==2">
						<div class="magicbox" :class="{'active':index==0}" @click="changeIndex(0)">
							<el-image v-if="plugins_data.data[0].image" class="img" :src="plugins_data.data[0].image" fit="cover"></el-image>
							<div v-else class="textStyle">宽度250PX</div>
						</div>
						<div class="magicbox" :class="{'active':index==1}" @click="changeIndex(1)">
							<el-image v-if="plugins_data.data[1].image" class="img" :src="plugins_data.data[1].image" fit="cover"></el-image>
							<div v-else class="textStyle">宽度250PX</div>
						</div>
						<div class="magicbox" :class="{'active':index==2}" @click="changeIndex(2)">
							<el-image v-if="plugins_data.data[2].image" class="img" :src="plugins_data.data[2].image" fit="cover"></el-image>
							<div v-else class="textStyle">宽度250PX</div>
						</div>
					</div>
					
					<div class="styleList styleHeight" v-if="plugins_data.type==3">
						<div class="left" :class="{'active':index==0}" @click="changeIndex(0)">
							<el-image v-if="plugins_data.data[0].image" class="img" :src="plugins_data.data[0].image" fit="cover"></el-image>
							<div v-else class="textStyle">375*750像素</div>
						</div>
						<div class="right">
							<div class="smallbox" :class="{'active':index==1}" @click="changeIndex(1)">
								<el-image v-if="plugins_data.data[1].image" class="img" :src="plugins_data.data[1].image" fit="cover"></el-image>
								<div v-else class="textStyle">375*375像素</div>
							</div>
							<div class="smallbox" :class="{'active':index==2}" @click="changeIndex(2)">
								<el-image v-if="plugins_data.data[2].image" class="img" :src="plugins_data.data[2].image" fit="cover"></el-image>
								<div v-else class="textStyle">375*375像素</div>
							</div>
						</div>
					</div>
					
					<div class="styleList styleHeight styleFour" v-if="plugins_data.type==4">
						<div class="left" :class="{'active':index==0}" @click="changeIndex(0)">
							<el-image v-if="plugins_data.data[0].image" class="img" :src="plugins_data.data[0].image" fit="cover"></el-image>
							<div v-else class="textStyle">750*375像素</div>
						</div>
						<div class="right">
							<div class="smallbox" :class="{'active':index==1}" @click="changeIndex(1)">
								<el-image v-if="plugins_data.data[1].image" class="img" :src="plugins_data.data[1].image" fit="cover"></el-image>
								<div v-else class="textStyle">375*375像素</div>
							</div>
							<div class="smallbox" :class="{'active':index==2}" @click="changeIndex(2)">
								<el-image v-if="plugins_data.data[2].image" class="img" :src="plugins_data.data[2].image" fit="cover"></el-image>
								<div v-else class="textStyle">375*375像素</div>
							</div>
						</div>
					</div>
					
					<div class="styleList styleHeight" v-if="plugins_data.type==5">
						<div class="left" :class="{'active':index==0}" @click="changeIndex(0)">
							<el-image v-if="plugins_data.data[0].image" class="img" :src="plugins_data.data[0].image" fit="cover"></el-image>
							<div v-else class="textStyle">375*750像素</div>
						</div>
						<div class="right">
							<div class="smallbox" :class="{'active':index==1}" @click="changeIndex(1)" style="border-right: none;">
								<el-image v-if="plugins_data.data[1].image" class="img" :src="plugins_data.data[1].image" fit="cover"></el-image>
								<div v-else class="textStyle">375*375像素</div>
							</div>
							<div class="bottombox">
								<div class="smallbox" :class="{'active':index==2}" @click="changeIndex(2)" style="border-bottom: none;">
									<el-image v-if="plugins_data.data[2].image" class="img" :src="plugins_data.data[2].image" fit="cover"></el-image>
									<div v-else class="textStyle">188*375像素</div>
								</div>
								<div class="smallbox" :class="{'active':index==3}" @click="changeIndex(3)">
									<el-image v-if="plugins_data.data[3].image" class="img" :src="plugins_data.data[3].image" fit="cover"></el-image>
									<div v-else class="textStyle">188*375像素</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="box">
				<div class="label"></div>
				<div class="linkbox">
					<div class="imgbox" @click="imgshow=true">
						<el-image v-if="plugins_data.data[index].image" class="img" :src="plugins_data.data[index].image" fit="cover"></el-image>
						<i class="el-icon-plus icon" v-if="!plugins_data.data[index].image"></i>
						<span class="text" v-if="!plugins_data.data[index].image">添加图片</span>
					</div>
					<div class="linkbox">
						<el-input v-if="plugins_data.data[index].link" v-model="plugins_data.data[index].link.data.name" disabled style="color: #333;!important">
						    <div slot="prepend"><i class="el-icon-link linkIcon"></i></div>
						    <div slot="append"><span class="changebtn" @click="openLink">修改</span></div>
						</el-input>
						<!-- <div class="addlink" v-else @click="openLink"><i class="el-icon-link"></i> 选择链接</div> -->
						<div class="addlink" v-else @click="openLink(index)"><i class="el-icon-link"></i> 选择链接</div>
					</div>
				</div>
			</div>
			
			<div class="linesty"></div>
			<div class="box">
				<div class="label">内部间距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.margin_top" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.margin_top">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">左右边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.margin_right" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.margin_right">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.margin_bottom" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.margin_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.radius_top" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.radius_top">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.radius_bottom" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.radius_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
		</div>

		<chooselink :chooseShow.sync="chooseShow" @confirm="confirm"></chooselink>
		<xsk-manage :managehide.sync="imgshow" is_rest @confirm="pickimg"></xsk-manage>
	</div>
</template>

<script>
import chooselink from '../../common/chooselink.vue'	
export default {
	name: 'magic',
	components:{chooselink},
	data() {
		return {
			index:0,
			chooseShow:false,
			imgshow:false,
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	methods:{
		openLink(){
			this.chooseShow = true
		},
		//选择链接
		confirm(e){
			let item = this.plugins_data.data[this.index]
			item.link = e
				// 不提交自定义页面的content  thunmbnail
				if(item.link.type=="diypage"){
					item.link.data.content=null
					item.link.data.thumbnail=null
				}
			this.$set(this.plugins_data.data,this.index,item)
		},
		//添加图片
		pickimg(e){
			console.log(e)
			if(e.imgobjs.length>0){
				let item = this.plugins_data.data[this.index]
				item.image = e.imgobjs[0].qiniu_url
				this.$set(this.plugins_data.data,this.index,item)
			}
		},
		changeIndex(index){
			this.index = index
		},
		changeType(type){
			if(this.plugins_data.type != type){
				this.plugins_data.type = type
				this.index = 0
			}
		},
	}
};
</script>

<style scoped lang="less">
@import "../../style/pluginsCommon.css";

.styleList{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 187.5px;
	width: 100%;
	border: 1px solid #e3e3e3;
	.active{
		background: rgba(45,140,240,0.2);
		border: 1px solid #2d8cf0 !important;
	}
	.magicbox{
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		width: 100% !important;
		height: 100%;
		border-right: 1px solid #e3e3e3;
		&:last-child{
			border-right: none;
		}
		.img{
			width: 100%;
			height: 100%;
		}
	}
	.noborder{
		border: none;
	}
	.textStyle{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		color: #2d8cf0;
	}
	.left{
		flex: 1;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 1px solid #e3e3e3;
		.img{
			width: 100%;
			height: 100%;
		}
	}
	.right{
		flex: 1;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.bottombox{
			width: 100%;
			display: flex;
		}
		.smallbox{
			flex: 1;
			width: 100%;
			height: 187.5px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-right: 1px solid #e3e3e3;
			border-bottom: 1px solid #e3e3e3;
			&:last-child{
				border: none;
			}
			.img{
				width: 100%;
				height: 100%;
			}
		}
	}
}

.styleFour{
	flex-direction: column !important;
	.left{
		border-bottom: 1px solid #e3e3e3;
		border-right: none;
		height: 50%;
	}
	.right{
		flex-direction: row !important;
		.smallbox{
			border: none;
			&:first-child{
				border-right: 1px solid #e3e3e3;
			}
		}
	}
}
.styleHeight{
	height: 375px;
}

.btnlist{
	width:100%;
	display:flex;
	border:1px solid #e3e3e3;
	border-radius: 4px;
	.btn{
		flex:1;
		text-align:center;
		font-size: 12px;
		padding: 6px 0;
		cursor: pointer;
	}
	.active{
		background: #1989fa;
		color: #FFFFFF;
	}
}

.linkbox{
	flex: 1;
	background: #f4f6f8;
	padding: 10px;
	display: flex;
	align-items: center;
	.imgbox{
		width: 60px;
		height: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: #FFFFFF;
		border: 1px solid #E9EDEF;
		.img{
			width: 100%;
			height: 100%;
		}
		.icon{
			font-size: 22px;
		}
		.text{
			font-size: 12px;
			color: #B8B9BD;
		}
	}
	.addlink{
		flex: 1;
		height: 32px;
		line-height: 32px;
		border: 1px solid #dcdee2;
		color: #409eff;
		padding: 0 10px;
		box-sizing: border-box;
		border-radius: 4px;
		cursor: pointer;
		background: #FFFFFF;
		&:hover{
			border-color: #409eff;
		}
	}
	.changebtn{
		color: #409eff;
		cursor: pointer;
	}
	.linkIcon{
		color: #409eff;
		font-size: 16px;
	}
}
</style>
