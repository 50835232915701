<template>
	<div class="plugins">
		<div>
			<div class="headsty">组件样式</div>
			<div class="box">
				<div class="label">组件背景:</div>
				<el-radio v-model="plugins_data.config.style" :label="'solid'">实线</el-radio>
				<el-radio v-model="plugins_data.config.style" :label="'double'">双实线</el-radio>
				<el-radio v-model="plugins_data.config.style" :label="'dashed'">虚线1</el-radio>
				<el-radio v-model="plugins_data.config.style" :label="'dotted'">虚线2</el-radio>
			</div>
		</div>
		
		<div>
			<div class="headsty">内容样式</div>
			<div class="box">
				<div class="label">线条颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.color"></el-color-picker>
				<el-input v-model="plugins_data.style.color" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.color = '#999999'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">线条高度:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.height" :max="20" :min="1"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.height">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上下间距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.margin" :max="30"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.margin">
					<template slot="append">PX</template>
				</el-input>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'guide',
	data() {
		return {
			
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	methods:{
		
	}
};
</script>

<style scoped lang="less">
@import "../../style/pluginsCommon.css";

</style>
