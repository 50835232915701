var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content",class:{'scroll':_vm.plugins_data.cofig.showType==2},style:({'padding':`${_vm.plugins_data.style.padding_top}px ${_vm.plugins_data.style.padding_left}px ${_vm.plugins_data.style.padding_bottom}px `})},_vm._l((_vm.plugins_data.menus),function(item,key){return _c('div',{key:key,staticClass:"list",style:({
          borderRadius:
            _vm.plugins_data.style.border_radius_top / 2 +'px ' +
            _vm.plugins_data.style.border_radius_top / 2 +'px ' +
            _vm.plugins_data.style.border_radius_bottom / 2 +'px ' +
            _vm.plugins_data.style.border_radius_bottom / 2 +'px',
            width: ((100/_vm.plugins_data.cofig.number)-4)+'%',
        })},[_c('div',{staticClass:"list-img"},[_c('el-image',{staticStyle:{"width":"100%","min-height":"80px"},attrs:{"src":item.images ||require('assets/icon/goods_col2.png'),"fit":"cover"}}),_c('div',{staticClass:"topTitle",style:({color:_vm.plugins_data.style.topTitleColor,'text-align':_vm.plugins_data.style.topTitleAlign})},[_vm._v(" "+_vm._s(item.topTitle))])],1),_c('div',{staticClass:"bottomTitle",style:({color:_vm.plugins_data.style.bottomTitleColor,'text-align':_vm.plugins_data.style.bottomTitleAlign})},[_vm._v(" "+_vm._s(item.bottomTitle)+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }