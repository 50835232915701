import Layout from '../layout/components/mainbox.vue'
import store from '@/store/index.js'
import Vue from 'vue'
import recom from '../layout/components/recom.vue'

// 转component字段的字符串为import
export function changeroute(routers) {
	let routerTree = []
	routerTree = assembleroute(routers,1).routerTree
	sessionStorage.setItem('routestext',JSON.stringify(routerTree))
	//已完成组装 改变vuex状态
	store.commit('routestatus', 1)
	return routerTree
}

//递归组装路由
function assembleroute (routers,type){
	let routerTree = []
	let plugin = []
	
	routers.forEach(item=>{
		let obj = {
			path: item.path,
			name: item.vue_name,
			meta:{
				title:item.name,
				layout:item.layout,
				router_name:item.router_name,
				icon:item.icon,
				notright:item.notright,
				keep:item.keep_alive,
				// keepAlive:true,
				level:type,
			}
		}
		
		if(item.props){
			obj.meta.props = item.props
		}
		
		if(item.component == 'Layout'){
			Vue.set(obj, 'component', recom)
		}else{
			Vue.set(obj, 'component', (resolve) => require([`@/views/${item.component}`],resolve))
		}
				
		if(item._child&&item._child.length>0){
			obj.children = assembleroute(item._child,type+1).routerTree
			obj.plugin = assembleroute(item._child,type+1).plugin
		}
		
		if(item.app_level==0||item.app_level==2){
			routerTree.push(obj)
		}else{
			plugin.push(obj)
		}
	})

	return {
		'routerTree':routerTree,
		'plugin':plugin
	} 
}