var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
	'paddingLeft':_vm.plugins_data.style.margin_left*((375-_vm.plugins_data.style.margin_left-_vm.plugins_data.style.margin_left)/750)+'px',
	'paddingRight':_vm.plugins_data.style.margin_left*((375-_vm.plugins_data.style.margin_left-_vm.plugins_data.style.margin_left)/750)+'px',
}),attrs:{"id":"hotarea"}},[_c('div',{staticClass:"contentbox",style:({
			'marginTop':_vm.plugins_data.style.margin_top*((375-_vm.plugins_data.style.margin_left-_vm.plugins_data.style.margin_left)/750)+'px',
			'marginBottom':_vm.plugins_data.style.margin_bottom*((375-_vm.plugins_data.style.margin_left-_vm.plugins_data.style.margin_left)/750)+'px',
			'borderRadius':_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px',
		})},[_c('el-image',{ref:"elimg",staticClass:"img",attrs:{"src":_vm.plugins_data.data.image||require('assets/icon/goods_col2.png')},on:{"load":_vm.imgload}}),_vm._l((_vm.plugins_data.data.list),function(item){return _c('div',{key:item.key,staticClass:"box",style:({
			'width':item.width*(_vm.nowWidth/_vm.plugins_data.data.initWidth)+'px',
			'height':item.height*(_vm.nowHeight/_vm.plugins_data.data.initHeight)+'px',
			'top':item.top*(_vm.nowHeight/_vm.plugins_data.data.initHeight)+'px',
			'left':item.left*(_vm.nowWidth/_vm.plugins_data.data.initWidth)+'px',
		})},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.link?item.link.data.name:'选择链接'))])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }