<!-- view窗口 -->
<template>
  <div :style="{'padding-top':top+'rpx'}">
    <div class="box">
      <div class="tabs-content" :style="{ 'background': plugins_data.headStyle.tabBackground }">
        <div class="tabs-content-item" v-for="(item, i) in plugins_data.tabs" :key="i" @click="changeTab(i)" :style="{'color':(plugins_data.tabActive == i?plugins_data.headStyle.tabActiveColor:plugins_data.headStyle.tabColor),
          'background':(plugins_data.tabActive == i?plugins_data.headStyle.tabActiveBg:'')}">
          {{ item.text }}
          <i class="tabs-item-active" v-if="plugins_data.tabActive == i"
            :style="{ 'background': plugins_data.headStyle.tabActiveColor }"></i>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="listbox"
        :class="plugins_data.style[plugins_data.tabActive].type == 1 ? 'doublelist' : plugins_data.style[plugins_data.tabActive].type == 2 ? 'threelist' : plugins_data.style[plugins_data.tabActive].type == 3 ? 'textlist' : 'list'"
        :style="{
          paddingLeft: plugins_data.style[plugins_data.tabActive].padding_left / 2 + 'px',
          paddingRight: plugins_data.style[plugins_data.tabActive].padding_left / 2 + 'px',
          paddingTop: plugins_data.style[plugins_data.tabActive].padding_top / 2 + 'px',
          paddingBottom: plugins_data.style[plugins_data.tabActive].padding_bottom / 2 + 'px',
          background: plugins_data.style[plugins_data.tabActive].card_bg_type === 1 ? 'rgba(0,0,0,0)' : plugins_data.style[plugins_data.tabActive].card_background,
        }">
        <template v-if="list.length == 0">
          <div class="goodsbox" v-for="(item, index) in plugins_data.style[plugins_data.tabActive] .type == 2 ? 3 : 2"
            :key="index" :style="{
              background: plugins_data.style[plugins_data.tabActive].goods_background,
              borderRadius: plugins_data.style[plugins_data.tabActive].border_radius_top / 2 + 'px ' + plugins_data.style[plugins_data.tabActive].border_radius_top / 2 + 'px ' + plugins_data.style[plugins_data.tabActive].border_radius_bottom / 2 + 'px ' + plugins_data.style[plugins_data.tabActive].border_radius_bottom / 2 + 'px',
            }">
            <div class="image">
              <el-image style="width: 100%; height: 100%" :src="require('assets/icon/goods_col2.png')" fit="cover">
              </el-image>
            </div>
            <div class="rightbox">
              <div class="goodsname">这里是商品名称这里是商品名称</div>
              <div class="desc"
                v-if="plugins_data.style[plugins_data.tabActive].type == 3 || plugins_data.style[plugins_data.tabActive].type == 4">
                这里是商品简介
              </div>
              <div class="pricebox">
                <div class="price" :style="{color: plugins_data.style[plugins_data.tabActive].paybtn_bg}">
                  ￥<span>20.00</span>
                </div>

                <!-- 加购 -->
                <i v-if="plugins_data.style[plugins_data.tabActive].paybtn_hidden"
                  :class="`el-icon-car${plugins_data.style[plugins_data.tabActive].paybtn_type}`"
                  :style="{'font-size':'24px','color':plugins_data.style[plugins_data.tabActive].paybtn_bg}"></i>
              </div>
            </div>
          </div>
        </template>

        <template v-if="list.length > 0">
          <template v-for="(item, index) in list">
            <div v-if="index < plugins_data.showNumber[plugins_data.tabActive]" class="goodsbox" :key="index" :style="{
                background:
                  plugins_data.style[plugins_data.tabActive].goods_background,
                borderRadius:
                  plugins_data.style[plugins_data.tabActive].border_radius_top /2 +'px ' +
                  plugins_data.style[plugins_data.tabActive].border_radius_top /2 +'px ' +
                  plugins_data.style[plugins_data.tabActive].border_radius_bottom /2 +'px ' +
                  plugins_data.style[plugins_data.tabActive].border_radius_bottom /2 +'px',
              }">
              <el-image class="image" :src="item.image" fit="cover"></el-image>
              <div class="rightbox">
                <div class="goodsname">{{ item.name }}</div>
                <div class="desc"
                  v-if="plugins_data.style[plugins_data.tabActive].type == 3 || plugins_data.style[plugins_data.tabActive].type == 4">
                  {{ item.desc }}
                </div>
                <div class="pricebox">
                  <div class="price" v-if="item.is_spec" :style="{
                      color:plugins_data.style[plugins_data.tabActive].paybtn_bg,
                    }">
                    ￥<span>{{ item.min_price }}</span>~￥<span>{{ item.max_price }}</span>
                  </div>
                  <div class="price" v-else :style="{
                      color:plugins_data.style[plugins_data.tabActive].paybtn_bg,
                    }">
                    ￥<span>{{ item.selling_price }}</span>
                  </div>
                  <!-- 加购 -->
                  <i v-if="plugins_data.style[plugins_data.tabActive].paybtn_hidden"
                    :class="`el-icon-car${plugins_data.style[plugins_data.tabActive].paybtn_type}`"
                    :style="{'font-size':'24px','color':plugins_data.style[plugins_data.tabActive].paybtn_bg}"></i>
                </div>
              </div>
            </div>
          </template></template>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    eltips
  } from "@/util/util.js";
  export default {
    name: "tabsgroup",
    data() {
      return {
        list: [],
        top: 0
      };
    },
    props: {
      plugins_data: {
        type: Object
      },
    },
    watch: {
      plugins_data: {
        //监听的对象
        deep: true, //深度监听设置为 true
        handler: function (newV, oldV) {
          this.getList();
        },
      },
    },
    mounted() {
      // this.getList();
    },
    methods: {
      changeTab(i) {
        this.plugins_data.tabActive = i;
        // this.getList()
      },
      getList() {
        let data = {
          page: 1,
          status: 1,
          limit: this.plugins_data.goods_option.showNumber[this.plugins_data.tabActive],
          sort_field: this.plugins_data.goods_option.sort_field[this.plugins_data.tabActive],
          sort_type: this.plugins_data.goods_option.sort_type[this.plugins_data.tabActive],
        };
        let type = this.plugins_data.goods_option[this.plugins_data.tabActive].type;

        // 1、自选商品 2、分类商品 3、分组商品 4、营销属性
        if (type == 1) {
          if (this.plugins_data.goods[this.plugins_data.tabActive].length > 0) {
            data.ids = this.plugins_data.goods[this.plugins_data.tabActive].map(item => item.id).join(',')
          } else {
            this.list = [];
            return;
          }
        }
        if (type == 2) {
          if (this.plugins_data.category[this.plugins_data.tabActive].length > 0) {
            data.category_id = this.plugins_data.category[this.plugins_data.tabActive].id;
          } else {
            this.list = [];
            return;
          }
        }
        if (type == 3) {
          if (this.plugins_data.group[this.plugins_data.tabActive].length > 0) {
            data.group_id = this.plugins_data.group[this.plugins_data.tabActive].id;
          } else {
            this.list = [];
            return;
          }
        }
        if (type == 4) {
          if (this.plugins_data.marketing_label.length > 0) {
            data.marketing_label = this.plugins_data.marketing_label[this.plugins_data.tabActive];
          } else {
            this.list = [];
            return;
          }
        }

        this.axios.get("/api/retail/admin/goods/goods", {
          params: data
        }).then((res) => {
          if (data.ids) {
            let list = res.data.data;
            let ids = data.ids.split(',')
            list.sort((a, b) => {
              let ad = a.id.toString()
              let bd = b.id.toString()
              if (ids.indexOf(ad) < ids.indexOf(bd)) return -1;
              if (ids.indexOf(ad) > ids.indexOf(bd)) return 1;
              return 0;
            })
            this.list = list;
          } else {
            this.list = res.data.data;
          }
        });
      },
    },
  };
</script>

<style scoped lang="less">
  div {
    box-sizing: border-box !important;
  }

  .tabs-content {
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 4px;

    .tabs-content-item {
      padding: 0 10px;
      box-sizing: border-box;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      position: relative;

      .tabs-item-active {
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 30px;
        left: calc(50% - 15px);
      }
    }
  }

  .box {
    margin: 0 auto;
    width: 373px;
  }

  .listbox {
    width: 373px;
    overflow: hidden;

    .goodsbox {
      margin-bottom: 8px;
      margin-right: 8px;
      overflow: hidden;

      .image {
        display: block;
        width: 100%;
        height: 170px;
      }
    }

    .rightbox {
      position: relative;
      padding: 6px 8px;

      .goodsname {
        margin-bottom: 6px;
        font-size: 14px;
        line-height: 18px;
      }

      .pricebox {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
          font-size: 12px;

          span {
            font-weight: bold;
          }
        }

        .addcar {
          cursor: pointer;
          position: relative;
          // right: -4px;
        }

        .paybg {
          line-height: 20px;
          font-size: 12px;
          transform: scale(0.8);
          padding: 0 4px;
          border-radius: 2px;
          background: #ff0000;
          color: #ffffff;
        }

        .paytext {
          line-height: 18px;
          font-size: 12px;
          transform: scale(0.8);
          padding: 0 4px;
          border-radius: 2px;
          background: #ffffff;
          color: #ff0000;
          border: 1px solid #ff0000;
        }
      }
    }
  }

  .doublelist {
    .goodsbox {
      vertical-align: top;
      display: inline-block;
      width: calc(50% - 4px);

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    .goodsname {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .threelist {
    .goodsbox {
      display: inline-block;
      width: 32%;
      margin-right: 2%;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .image {
        height: 110px;
      }
    }

    .goodsname {
      height: 36px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  .textlist {
    .goodsbox {
      display: flex;
      padding: 8px 12px;
      margin-bottom: 0;
      margin-right: 0;
      border-bottom: 1px solid #f3f3f3;

      .image {
        flex: 0 0 110px;
        width: 110px;
        height: 110px;
      }

      .rightbox {
        flex: 1;
        position: relative;
        padding: 0;

        .desc {
          font-size: 12px;
          color: #999999;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }

        .pricebox {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0px;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }

  .list {
    .goodsbox {
      margin-right: 0;

      .image {
        height: 160px;
      }
    }

    .rightbox {
      .desc {
        font-size: 12px;
        color: #999999;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
</style>