export let shoppingLinkArr = [
	{
		title: '商城页面',
		child: [
			{ id: 1, name: '商城首页', path: '/pages/index/index' },
			// {id:2,name:'分类导航',path:'/pages/index/index'},
			// {id:3,name:'全部商品',path:'/pages/index/index'},
			// {id:4,name:'购物车',path:'/pages/index/index'},
		]
	},
	{
		title: '会员中心',
		child: [
			{ id: 5, name: '会员卡', path: '/pages/me/myCode' },
			// {id:6,name:'会员等级说明',path:'/pages/index/index'},
			// {id:7,name:'我的订单(全部)',path:'/pages/index/index'},
			// {id:8,name:'待付款订单',path:'/pages/index/index'},
			// {id:9,name:'待发货订单',path:'/pages/index/index'},
			// {id:10,name:'待收货订单',path:'/pages/index/index'},
			// {id:11,name:'已完成订单',path:'/pages/index/index'},
			// {id:12,name:'维权订单',path:'/pages/index/index'},
			// {id:13,name:'我的收藏',path:'/pages/index/index'},
			// {id:14,name:'我的足迹',path:'/pages/index/index'},
			// {id:15,name:'会员充值',path:'/pages/index/index'},
			// {id:16,name:'余额明细',path:'/pages/index/index'},
			// {id:17,name:'积分明细',path:'/pages/index/index'},
			// {id:18,name:'余额提现',path:'/pages/index/index'},
			// {id:19,name:'我的资料',path:'/pages/index/index'},
			// {id:20,name:'积分排行',path:'/pages/index/index'},
			// {id:21,name:'消费排行',path:'/pages/index/index'},
			// {id:22,name:'收货地址管理',path:'/pages/index/index'},
			// {id:23,name:'红包领取明细',path:'/pages/index/index'},
		]
	},
	// {
	// 	title:'分销',
	// 	child:[
	// 		{id:24,name:'分销中心',path:'/pages/index/index'},
	// 		{id:25,name:'等级说明',path:'/pages/index/index'},
	// 		{id:26,name:'分销订单',path:'/pages/index/index'},
	// 		{id:27,name:'分销佣金',path:'/pages/index/index'},
	// 		{id:28,name:'我的下线',path:'/pages/index/index'},
	// 		{id:29,name:'提现明细',path:'/pages/index/index'},
	// 		{id:30,name:'佣金排名',path:'/pages/index/index'},
	// 	]
	// },
	// {
	// 	title:'多商户会员中心',
	// 	child:[
	// 		{id:31,name:'商户入驻',path:'/pages/index/index'},
	// 		{id:32,name:'推广管理',path:'/pages/index/index'},
	// 	]
	// },
	// {
	// 	title:'客服',
	// 	child:[
	// 		{id:33,name:'客服系统',path:'/pages/index/index'},
	// 	]
	// },
	// {
	// 	title:'优惠券',
	// 	child:[
	// 		{id:34,name:'领取优惠券',path:'/pages/index/index'},
	// 		{id:35,name:'我的优惠券',path:'/pages/index/index'},
	// 	]
	// },
	{
		title: '功能页面',
		child: [
			// {id:34,name:'售货机扫码',path:'/pages/index/index',type:'code'},
			{ id: 35, name: '扫码付款', path: '/pages/index/index', type: 'paycode' },
			{ id: 36, name: '付费会员卡', path: '/pages/vip/index' },
			{ id: 37, name: '商家入驻', path: '/pages/merchant/index' },
			{ id: 38, name: '缴费', path: '/pages/cemetery/index' },
			{ id: 39, name: '积分商城', path: '/pages/integral/integral' },
			{ id: 40, name: '微商代理', path: '/pages/agentCenter/agentCenter' },
			{ id: 41, name: '区域代理', path: '/pages/regionalAgent/agentCenter' },
			{ id: 42, name: '记事簿', path: '/pages/newpage/notebook' },
			{ id: 43, name: '分销中心', path: '/pages/disCenter/index' },
			{ id: 44, name: '订单中心', path: '/pages/order/orderList' },
			{ id: 45, name: '收货地址', path: '/pages/me/address' },
			{ id: 46, name: '优惠券', path: '/pages/me/couponList' },
			{ id: 47, name: '关于我们', path: '/pages/me/about' },
			{ id: 48, name: '联系客服', path: 'contact' },
			{ id: 49, name: '心理测评', path: '/pages/newpage/selfAssessment' },
			{ id: 50, name: '学习', path: '/pages/newpage/study' },
			{ id: 51, name: '合伙人', path: '/partner/pages/partner' },
			{ id: 52, name: '合伙人资产', path: '/partner/pages/team' },
			{ id: 53, name: '商品归属', path: '/partner/pages/belong' },
			{ id: 54, name: '区域代理', path: '/pagesArea/pages/area' },
			{ id: 55, name: '公排', path: '/pagesPublic/pages/index' },
		]
	},
]