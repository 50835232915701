<template>
	<div id="businessData" class="plugins">
		<div>
			<div class="headsty">组件样式</div>
			<div class="box" id="defaultImages">
				<div class="label">组件类型:</div>
				<el-radio-group v-model="plugins_data.data.storeStyle" size="small">
					<el-radio-button label="1" border>商户商品</el-radio-button>
					<el-radio-button label="2" border>商户列表</el-radio-button>
				</el-radio-group>
			</div>
			<div class="headsty">添加商家</div>
			<div class="box">
				<div class="label">选择商家:</div>
				<el-radio-group v-model="plugins_data.data.storeType">
				    <el-radio :label="1">手动选择</el-radio>
				    <!-- <el-radio :label="4">商家分类</el-radio> -->
				    <el-radio :label="2">商家分组</el-radio>
				    <el-radio :label="3">按距离显示</el-radio>
				</el-radio-group>
			</div>
			<div class="box" v-if="plugins_data.data.storeType!=3">
				<div class="tabelbox">
					<div class="headbox list">
						<div class="icon"></div>
						<div class="left">{{plugins_data.data.storeType==1?'商家名称':plugins_data.data.storeType==2?'分组名称':'分类名称'}}</div>
						<div class="right">操作</div>
					</div>
					<div class="list" style="justify-content: center;" v-if="
						(plugins_data.data.storeType==1&&plugins_data.data.storeList.length==0)||
						(plugins_data.data.storeType==2&&!plugins_data.data.category)
						">
						暂无数据
					</div>
					
					<template v-if="plugins_data.data.storeType==1&&plugins_data.data.storeList.length>0">
						<draggable v-model="plugins_data.data.storeList" :options="{ animation: 200 }" >
							<div class="list" v-for="(item,index) in plugins_data.data.storeList" :key="index">
								<div class="icon"><i class="el-icon-s-operation"></i></div>
								<div class="left">
									<el-image class="logo" :src="item.logo" fit="cover"></el-image>
									<div class="name">{{item.name}}</div>
								</div>
								<div class="right">
									<el-button type="text" size="small" @click="openStore(2,item,index)">替换</el-button>
									<el-button type="text" size="small" style="color: #FF0000;" @click="delStore(index)">删除</el-button>
								</div>
							</div>
						</draggable>
					</template>
					
					<template v-if="plugins_data.data.storeType==2&&plugins_data.data.category">
						<div class="list">
							<div class="icon"><i class="el-icon-s-operation"></i></div>
							<div class="left">
								<div class="name">{{plugins_data.data.category.name}}</div>
							</div>
							<div class="right">
								<el-button type="text" size="small" @click="openCategory(2)">替换</el-button>
								<el-button type="text" size="small" @click="delCategory" style="color: #FF0000;">删除</el-button>
							</div>
						</div>
					</template>
					
					<div class="list" style="justify-content: center;" v-if="plugins_data.data.storeType==1">
						<el-button type="text" size="medium" @click="openStore(1)">+添加商家</el-button>
					</div>
					<div class="list" style="justify-content: center;" v-if="plugins_data.data.storeType==2&&!plugins_data.data.category">
						<el-button type="text" size="medium" @click="openCategory(1)">+添加分类</el-button>
					</div>
				</div>
			</div>
			<div class="box" v-if="plugins_data.data.storeType!=1">
				<div class="label">显示数量:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.data.limit" :max="40" :min="1"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.data.limit">
					<template slot="append">个</template>
				</el-input>
			</div>
		</div>
		
		<div>
			<div class="headsty">内容样式</div>
			<div class="box">
				<div class="label">组件背景:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.background"></el-color-picker>
				<el-input v-model="plugins_data.style.background" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.background = '#FFFFFF'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">商家logo:</div>
				<el-radio-group v-model="plugins_data.data.logoType">
				    <el-radio :label="1">圆形</el-radio>
				    <el-radio :label="2">圆角</el-radio>
				</el-radio-group>
			</div>
			<div class="box">
				<div class="label">商家距离:</div>
				<el-radio-group v-model="plugins_data.data.showLocatin">
				    <el-radio :label="1">开启</el-radio>
				    <el-radio :label="0">关闭</el-radio>
				</el-radio-group>
			</div>
			<div class="box">
				<div class="label">商家标题:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.color"></el-color-picker>
				<el-input v-model="plugins_data.style.color" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.color = '#333333'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">商品数量:</div>
				<el-radio-group v-model="plugins_data.data.showTotal">
				    <el-radio :label="1">开启</el-radio>
				    <el-radio :label="0">关闭</el-radio>
				</el-radio-group>
			</div>
			<div class="box">
				<div class="label">进店按钮:</div>
				<el-radio-group v-model="plugins_data.data.showBtn">
				    <el-radio :label="1">开启</el-radio>
				    <el-radio :label="0">关闭</el-radio>
				</el-radio-group>
			</div>
		</div>
		
		<div>
			<div class="headsty">商品样式</div>
			<div class="box">
				<div class="label">内部间距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.margin_bottom" :max="30"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.margin_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_top" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.padding_top">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">左右边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_left" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.padding_left">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_bottom" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.padding_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.border_radius_top">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model="plugins_data.style.border_radius_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
		</div>
		
		<el-dialog
			title="选择商家"
			:visible.sync="storeShow"
			:modal-append-to-body="false"
			center
			width="700px">
			<div class="goodsbox" v-loading="loading">
				<div class="headbox">
					<el-form :inline="true">
					  <el-form-item label="商家名称">
						<el-input v-model="keyword" placeholder="请输入商家名称"></el-input>
					  </el-form-item>
					  <el-form-item>
						<el-button type="primary" size="small" @click="changepage(1)">查询</el-button>
					  </el-form-item>
					</el-form>
				</div>
				
				<div class="dialogtabel">
					<el-table :data="storeList" style="width: 100%" stripe :header-cell-style="{ background: '#fafafa', color: '#999' }">
						<el-table-column label="商家" width="80">
							<template slot-scope="scope">
								<el-image style="width: 58px;height: 58px;" :src="scope.row.logo" fit="cover"></el-image>
							</template>
						</el-table-column>
						<el-table-column prop="name" label="">
							<template slot-scope="scope">
								<span class="name">{{scope.row.name}}</span>
							</template>
						</el-table-column>
						
						<el-table-column prop="total_stock" label="库存" width="160" align="center"></el-table-column>
						<el-table-column label="操作" width="80" align="center">
							<template slot-scope="scope">
								<div class="choosebtn choose" @click="delChoose(scope.row)" v-if="choose_id.indexOf(scope.row.id)!=-1">已选</div>
								<div class="choosebtn" v-else @click="chooseStore(scope.row)">选择</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				
				<div class="pages" v-if="total > 5">
					<el-pagination
						background
						:hide-on-single-page="true"
						@current-change="changepage"
						:current-page="page"
						:page-size="5"
						layout="total, prev, pager, next, jumper"
						:total="total"
					></el-pagination>
				</div>
			</div>
			
			<span slot="footer" class="dialog-footer">
				<el-button @click="storeShow = false">取 消</el-button>
				<el-button type="primary" @click="chooseConfirm">确 定</el-button>
			</span>
		</el-dialog>
		
		<el-dialog
			title="选择分类"
			:visible.sync="categoryShow"
			:modal-append-to-body="false"
			center
			width="700px">
			<div class="goodsbox" v-loading="loading">
				<div class="dialogtabel">
					<el-table :data="categoryList" style="width: 100%" stripe :header-cell-style="{ background: '#fafafa', color: '#999' }">
						<el-table-column prop="name" label="分类名称">
							<template slot-scope="scope">
								<span class="name">{{scope.row.name}}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="80" align="center">
							<template slot-scope="scope">
								<div class="choosebtn choose" @click="category=''" v-if="scope.row.id==category.id">已选</div>
								<div class="choosebtn" v-else @click="category = scope.row">选择</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			
			<span slot="footer" class="dialog-footer">
				<el-button @click="categoryShow = false">取 消</el-button>
				<el-button type="primary" @click="categoryConfirm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
import draggable from 'vuedraggable';
export default {
	name: 'businessData',
	components:{draggable},
	data() {
		return {
			storeList:[],
			keyword:'',
			page:1,
			total:0,
			storeShow:false,
			categoryShow:false,
			loading:false,
			choose:[],
			choose_id:[],
			replace_type:1, //1、添加 2、替换
			replace_index:-1,
			categoryList:[],
			category:'',
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	created() {
		this.getStoreList()
		this.getCategoryList()
	},
	methods:{
		delCategory(){
			this.plugins_data.data.category = ''
		},
		openCategory(type){
			if(type==1){
				this.category = ''
			}
			if(type==2){
				this.category = JSON.parse(JSON.stringify(this.plugins_data.data.category))
			}
			this.categoryShow = true
		},
		categoryConfirm(){
			this.plugins_data.data.category = JSON.parse(JSON.stringify(this.category))
			this.categoryShow = false
		},
		getCategoryList(){
			this.axios.get('/api/store/store_category').then(res=>{
				if(res.code==200){
					this.categoryList = res.data
				}else{
					eltips(res.msg,'error')
				}
			})
		},
		openStore(type,item,index){
			this.replace_type = type
			if(type==1){
				this.choose = []
				this.choose_id = []
				this.plugins_data.data.storeList.forEach(item=>{
					this.choose.push(item)
					this.choose_id.push(item.id)
				})
			}
			
			if(type==2){
				this.replace_index = index
				this.choose = [item]
				this.choose_id = [item.id]
			}
			this.storeShow = true
		},
		//删除商品
		delStore(index) {
			this.plugins_data.data.storeList.splice(index, 1);
		},
		chooseStore(item){
			if(this.replace_type==1){
				this.choose.push(item)
				this.choose_id.push(item.id)
			}else{
				this.choose = [item]
				this.choose_id = [item.id]
			}
		},
		delChoose(item){
			this.choose.splice(this.choose_id.indexOf(item.id),1)
			this.choose_id.splice(this.choose_id.indexOf(item.id),1)
		},
		chooseConfirm(){
			if(this.replace_type==1){
				this.choose.forEach(item=>{
					if(!this.plugins_data.data.storeList.find(e=>e.id==item.id)){
						this.plugins_data.data.storeList = [...this.plugins_data.data.storeList,...[item]] 
					}
				})
			}else{
				if(this.choose.length==0){
					this.plugins_data.data.storeList.splice(this.replace_index, 1);
				}else{
					let item = this.plugins_data.data.storeList[this.replace_index]
					item = this.choose[0]
					this.$set(this.plugins_data.data.storeList,this.replace_index,item)
				}
			}
			this.storeShow = false
		},
		changepage(e){
			this.page = e
			this.getStoreList()
		},
		getStoreList(){
			let data = {
				keyword:this.keyword,
				page:this.page,
				limit:5,
			}
			this.loading = true
			// this.axios.get('/api/store/store',{params:{data}}).then(res=>{
			this.axios.get('/api/store/store',{params:data}).then(res=>{
				this.loading = false
				this.total = res.data.total
				this.storeList = res.data.data
			})
		}
	}
};
</script>



<style scoped lang="less">
/deep/#defaultImages .el-radio__input{
   display: none !important;
 }
#businessData{
	.tabelbox{
		flex: 1;
		border: 1px solid #e9edef;
		.headbox{
			background: #f4f6f8 ;
			font-weight: bold;
		}
		.list{
			display: flex;
			align-items: center;
			padding:8px 12px;
			// border-bottom: 1px solid #e9edef;
			border-top: 1px solid #e9edef;
			// &:last-child{
			// 	border-bottom: none;
			// }
			&:first-child{
				border-top: none;
			}
			.icon{
				flex: 0 0 24px;
				margin-right: 12px;
				font-size: 24px;
				cursor: move;
			}
			.left{
				flex: 1;
				display: flex;
				align-items: center;
				.logo{
					width: 40px;
					height: 40px;
					margin-right: 6px;
				}
				.name{
					
				}
			}
			.right{
				flex: 0 0 70px;
				text-align: center;
			}
		}
		
	}
	
	.goodsbox{
		.choosebtn{
			width: 60px;
			line-height: 32px;
			border-radius: 2px;
			color: #2d8cf0;
			border: 1px solid #c3c3c3;
			cursor: pointer;
			&:hover{
				border-color: #2d8cf0;
			}
		}
		.choose{
			background: #2d8cf0;
			color: #FFFFFF;
			border-color: #2d8cf0;
		}
	}
}
</style>
