var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
	'paddingLeft':_vm.plugins_data.style.padding_left/2+'px',
	'paddingRight':_vm.plugins_data.style.padding_left/2+'px',
	'paddingTop':_vm.plugins_data.style.padding_top/2+'px',
	'paddingBottom':_vm.plugins_data.style.padding_bottom/2+'px',
	'backgroundColor':_vm.plugins_data.style.background,
}),attrs:{"id":"toppint-search"}},[_c('div',{staticClass:"searchbox",style:({
		'justifyContent':_vm.plugins_data.style.text_style,
		'backgroundColor':_vm.plugins_data.style.inputBackground,
		'borderRadius':_vm.plugins_data.style.border_radius/2+'px'
	})},[_c('i',{staticClass:"el-icon-search",style:({'color':_vm.plugins_data.style.color})}),_c('div',{staticStyle:{"margin-left":"4px"},style:({'color':_vm.plugins_data.style.color})},[_vm._v(_vm._s(_vm.plugins_data.placeholder)+" ")]),_c('div',{staticClass:"search-btn",style:({
			'color':_vm.plugins_data.style.btnColor,
			'borderRadius':_vm.plugins_data.style.border_radius/2-2+'px',
			'backgroundColor':_vm.plugins_data.style.background,
			})},[_vm._v("搜索")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }