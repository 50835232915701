<template>
	<div class="plugins">
		<div>
			<div class="headsty">组件样式</div>
			<!-- <div class="box">
				<div class="label">提示文字:</div>
				<el-input v-model="plugins_data.placeholder" placeholder="请输入提示文字" maxlength="20" show-word-limit></el-input>
			</div> -->
			<!-- <div class="box">
				<div class="label">对齐方式:</div>
				<el-radio v-model="plugins_data.style.text_style" :label="'center'">居中</el-radio>
				<el-radio v-model="plugins_data.style.text_style" :label="'left'">居左</el-radio>
			</div> -->
			<div class="box">
				<div class="label">背景颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.background">
				</el-color-picker>
				<el-input v-model="plugins_data.style.background" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.background = '#FFFFFF'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">文字颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.color">
				</el-color-picker>
				<el-input v-model="plugins_data.style.color" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.color = '#333333'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">输入框颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.inputBackground">
				</el-color-picker>
				<el-input v-model="plugins_data.style.inputBackground" style="width: 100px;margin-right: 6px;">
				</el-input>
				<el-button plain size="small" @click="plugins_data.style.inputBackground = '#FFFFFF'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">按钮字体颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.btnColor">
				</el-color-picker>
				<el-input v-model="plugins_data.style.btnColor" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.btnColor = '#333333'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">圆角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="linesty"></div>
			<div class="box">
				<div class="label">组件背景:</div>
				<el-radio v-model="plugins_data.style.plugin_back" :label="'opacity'">透明</el-radio>
				<el-radio v-model="plugins_data.style.plugin_back" :label="'color'">颜色</el-radio>
			</div>
			<div class="box" v-if="plugins_data.style.plugin_back==='color'">
				<div class="label"></div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.background">
				</el-color-picker>
				<el-input v-model="plugins_data.style.plugin_back_color" style="width: 100px;margin-right: 6px;">
				</el-input>
				<el-button plain size="small" @click="plugins_data.style.plugin_back_color = '#FFFFFF'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">上边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_top" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_top">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">左右边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_left" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_left">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_bottom" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		eltips
	} from '@/util/util.js';
	export default {
		name: 'search',
		data() {
			return {

			};
		},
		props: {
			plugins_data: {
				type: Object
			}
		},
		created() {

		},
		methods: {

		}
	};
</script>

<style scoped lang="less">
	@import "../../style/pluginsCommon.css";

	.plugins .box .label {
		flex: 0 0 100px;
	}
</style>