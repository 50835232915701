var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plugins"},[_c('div',{staticClass:"imgbox",style:({
		'paddingTop':_vm.plugins_data.style.padding_top/2+'px',
		'paddingBottom':_vm.plugins_data.style.padding_bottom/2+'px',
		'paddingLeft':_vm.plugins_data.style.padding_left/2+'px',
		'paddingRight':_vm.plugins_data.style.padding_left/2+'px'
	})},_vm._l((_vm.plugins_data.imgs),function(item,index){return _c('img',{key:index,staticClass:"img",style:({
			'borderRadius':_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px',
			'marginBottom':_vm.plugins_data.style.img_margin/2+'px'
		}),attrs:{"src":item.url||require('assets/images/img.png')}})}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }