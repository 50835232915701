<template>
	<div class="plugins">
		<div>
			<div class="headsty">组件样式</div>
			<div class="box">
				<div class="label">组件背景:</div>
				<el-radio v-model="plugins_data.style.text_style" :label="'left'">居左</el-radio>
				<el-radio v-model="plugins_data.style.text_style" :label="'center'">居中</el-radio>
			</div>
		</div>
		
		<div>
			<div class="headsty">内容样式</div>
			<div class="box">
				<div class="label">背景颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.background"></el-color-picker>
				<el-input v-model="plugins_data.style.background" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.background = '#FFFFFF'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">文字颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.color"></el-color-picker>
				<el-input v-model="plugins_data.style.color" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.color = '#333333'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">标题文字:</div>
				<el-input v-model="plugins_data.text" style="width: 214px" placeholder="请输入标题栏内容"></el-input>
			</div>
			<div class="box">
				<div class="label">跳转链接:</div>
				<el-button class="linkbox" plain size="medium" icon="el-icon-link" @click="chooseShow=true">{{plugins_data.link?plugins_data.link.data.name:'选择链接'}}</el-button>
			</div>
			<div class="linesty"></div>
			<div class="box">
				<div class="label">上边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_top" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_top">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">左右边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_left" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_left">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_bottom" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_top">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
		</div>
	
		<chooselink :chooseShow.sync="chooseShow" @confirm="confirm"></chooselink>
	</div>
</template>

<script>
import chooselink from '../../common/chooselink.vue'
export default {
	name: 'titles',
	components:{chooselink},
	data() {
		return {
			chooseShow:false,
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	methods:{
		confirm(e){
			this.plugins_data.link = e
			console.log(this.plugins_data)
		},
	}
};
</script>

<style scoped lang="less">
@import "../../style/pluginsCommon.css";

</style>
