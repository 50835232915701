<template>
  <div class="plugins">
    <div>
      <div class="headsty">组件状态</div>
      <div class="box">
        <div class="label">广告状态:</div>
        <el-radio-group v-model="plugins_data.cofig.status">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">启用</el-radio>
        </el-radio-group>
      </div>
      <div class="headsty">组件样式</div>
      <div class="box">
        <div class="label">广告样式:</div>
        <div class="shapebox">
          <div style="text-align: center">
            <div
              class="radiobox"
              :class="{ active: plugins_data.cofig.type == 1 }"
              @click="plugins_data.cofig.type = 1"
            >
              <div class="menustyle cir"><i class="el-icon-star-off"></i></div>
            </div>
            全屏
          </div>
          <div style="text-align: center">
            <div
              class="radiobox"
              :class="{ active: plugins_data.cofig.type == 2 }"
              @click="plugins_data.cofig.type = 2"
            >
              <div class="menustyle squ"><i class="el-icon-star-off"></i></div>
            </div>
            弹框1
          </div>
          <div style="text-align: center">
            <div
              class="radiobox"
              :class="{ active: plugins_data.cofig.type == 3 }"
              @click="plugins_data.cofig.type = 3"
            >
              <div class="menustyle squ"><i class="el-icon-star-off"></i></div>
            </div>
            弹框2
          </div>
        </div>
      </div>

      <div class="box">
        <div class="label"></div>
        <div style="background: #f4f6f8; padding: 10px; width: 100%">
          <template v-if="plugins_data.cofig.type != 1">
            <div class="box">
              <div class="label">背景颜色:</div>
              <el-color-picker
                style="margin-right: 20px"
                size="small"
                v-model="plugins_data.style.background"
              ></el-color-picker>
              <el-input
                v-model="plugins_data.style.background"
                style="width: 100px; margin-right: 6px"
              ></el-input>
              <el-button
                plain
                size="small"
                @click="plugins_data.style.background = '#FFFFFF'"
                >重置</el-button
              >
            </div>
            <div class="box">
              <div class="label">背景透明度:</div>
              <el-slider
                class="sliderstyle"
                style="flex: 0 0 100px"
                v-model="plugins_data.style.opacity"
                :max="100"
              ></el-slider>
              <el-input
                class="styleinput"
                type="number"
                v-model="plugins_data.style.opacity"
              >
                <span slot="append" style="width: 40px">%</span>
              </el-input>
            </div>
          </template>

          <template v-if="plugins_data.cofig.type == 1">
            <div class="box">
              <div class="label">定时关闭:</div>
              <el-radio-group v-model="plugins_data.cofig.closeTime">
                <el-radio style="margin: 5px" :label="0">关闭</el-radio>
                <el-radio style="margin: 5px" :label="3">3秒</el-radio>
                <el-radio style="margin: 5px" :label="5">5秒</el-radio>
                <el-radio style="margin: 5px" :label="10">10秒</el-radio>
                <el-radio style="margin: 5px" :label="15">15秒</el-radio>
              </el-radio-group>
            </div>
            <div class="box" v-if="plugins_data.cofig.closeTime!=0">
              <div class="label">关闭按钮:</div>
              <el-radio-group v-model="plugins_data.cofig.closeButton">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="1">开启</el-radio>
              </el-radio-group>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div>
      <div class="headsty">其他设置</div>
      <div class="box">
        <div class="label">显示设置:</div>
        <el-radio-group v-model="plugins_data.cofig.showType">
          <el-radio :label="0">每次显示</el-radio>
          <el-radio :label="1">间隔时间显示</el-radio>
        </el-radio-group>
      </div>

      <div class="box" v-if="plugins_data.cofig.showType==1">
        <div class="label"></div>
        <div style="background: #f4f6f8; padding: 10px; width: 100%">
            <div class="box">
                <div class="label">间隔时间:</div>
                <el-slider
                class="sliderstyle"
                style="flex: 0 0 100px"
                v-model="plugins_data.cofig.showTime"
                :max="240"
                ></el-slider>
                <el-input
                class="styleinput"
                type="number"
                v-model="plugins_data.cofig.showTime"
                >
                <template slot="append">分钟</template>
                </el-input>
            </div>
            </div>
      </div>
    </div>
    <div>
      <div class="headsty">内容</div>
      <el-tag size="medium" style="color: #939799"
      v-if="plugins_data.cofig.type == 1"
        >添加多个将以轮播图形式展现；广告图片建议尺寸1125x2436</el-tag
      >
      <el-tag size="medium" style="color: #939799" v-else
        >添加多个将以轮播图形式展现；广告图片建议尺寸840x1284</el-tag
      >
      <div class="listbox">
        <draggable v-model="plugins_data.menus" :options="{ animation: 200 }">
          <div
            class="list"
            v-for="(item, index) in plugins_data.menus"
            :key="index"
          >
            <i
              class="el-icon-error delicon"
              @click="delMenus(index)"
              v-if="plugins_data.menus.length > 1"
            ></i>
            <div class="left">
              <i class="el-icon-s-operation"></i>
            </div>
            <div class="right">
              <div class="box flexstart">
                <div class="label">按钮图片:</div>
                <div class="uploadbox">
                  <div class="imgbox" @click="openManage(index)">
                    <el-image
                      class="image"
                      v-if="item.images"
                      :src="item.images"
                      fit="cover"
                    ></el-image>
                    <i v-else class="el-icon-plus"></i>
                  </div>
                  <!-- <div class="tips">建议图片尺寸200*200，比例为1:1</div> -->
                </div>
              </div>
              <div class="box">
                <div class="label">跳转链接:</div>
                <div class="linkbox">
                  <el-input
                    v-if="item.link"
                    v-model="item.link.data.name"
                    disabled
                    style="color: #333;!important"
                  >
                    <div slot="prepend">
                      <i class="el-icon-link linkIcon"></i>
                    </div>
                    <div slot="append">
                      <span
                        class="changebtn"
                        @click="openLink(index, item.link)"
                        >修改</span
                      >
                    </div>
                  </el-input>
                  <div class="addlink" v-else @click="openLink(index)">
                    <i class="el-icon-link"></i> 选择链接
                  </div>
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <div class="addbtn" @click="addmenus">+添加</div>
      </div>
    </div>

    <chooselink :chooseShow.sync="chooseShow" @confirm="confirm"></chooselink>
    <xsk-manage
      :managehide.sync="imgshow"
      is_rest
      @confirm="pickimg"
    ></xsk-manage>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import chooselink from "../../common/chooselink.vue";
export default {
  name: "meuns",
  components: { draggable, chooselink },
  data() {
    return {
      imgshow: false,
      pickIndex: -1,
      chooseShow: false,
      index: "",
    };
  },
  props: {
    plugins_data: { type: Object },
  },
  created() {
  },
  methods: {
    openLink(index, item) {
      this.index = index;
      this.chooseShow = true;
    },
    confirm(e) {
      let item = this.plugins_data.menus[this.index];
      item.link = e;
      // 不提交自定义页面的content  thunmbnail
      if (item.link.type == "diypage") {
        item.link.data.content = null;
        item.link.data.thumbnail = null;
      }
      this.$set(this.plugins_data.menus, this.index, item);
    },
    //选择个数
    radioChange(e) {
      if (this.plugins_data.menus.length < e) {
        this.plugins_data.menus.push({
          url: "",
          text: "按钮文字" + (this.plugins_data.menus.length + 1),
          link: null,
        });
        this.radioChange(e);
      } else {
        return;
      }
    },
    //添加按钮
    addmenus() {
      this.plugins_data.menus.push({
        url: "",
        text: "按钮文字" + (this.plugins_data.menus.length + 1),
        link: null,
      });
    },
    //删除按钮
    delMenus(index) {
      this.plugins_data.menus.splice(index, 1);
    },
    //打开图片管理器
    openManage(index) {
      this.pickIndex = index;
      this.imgshow = true;
    },
    //选择图片
    pickimg(e) {
      let item = this.plugins_data.menus[this.pickIndex];
      item.images = e.imgobjs[0].qiniu_url;
      this.$set(this.plugins_data.menus, this.pickIndex, item);
    },
  },
};
</script>

<style scoped lang="less">
@import "../../style/pluginsCommon.css";
:deep(.el-input-group__append) {
  padding: 0 10px;
}
.shapebox {
  display: flex;
  .radiobox {
    padding: 4px 20px;
    border: 1px solid #cccccc;
    cursor: pointer;
    .menustyle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background: #939799;
      color: #ffffff;
      font-size: 14px;
    }
    .cir {
      border-radius: 50%;
    }
    .squ {
      border-radius: 4px;
    }
  }
  .active {
    border-right: 1px solid #1989fa !important;
    border: 1px solid #1989fa;
    .menustyle {
      background: #1989fa !important;
    }
  }
}

.listbox {
  margin: 10px 0 20px;
  .list {
    padding: 15px;
    background: #f4f6f8;
    border: 1px solid #e9edef;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    .delicon {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.3);
      position: absolute;
      right: -10px;
      top: -10px;
      display: none;
      cursor: pointer;
    }
    &:hover {
      .delicon {
        display: block;
      }
    }
    .left {
      flex: 0 0 30px;
      font-size: 20px;
      color: #b8b9bd;
    }
    .right {
      flex: 1;
      .box {
        &:last-child {
          margin-bottom: 0;
        }
        .label {
          flex: 0 0 70px;
          width: 70px;
        }
      }
      .flexstart {
        align-items: flex-start;
      }
      .uploadbox {
        .imgbox {
          width: 40px;
          height: 40px;
          border: 1px solid #e9edef;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 20px;
          color: #1989fa;
          cursor: pointer;
          background: #ffffff;
          .image {
            width: 38px;
            height: 38px;
          }
        }
        .tips {
          margin-top: 6px;
          color: #939799;
          font-size: 12px;
        }
      }
      .linkbox {
        .addlink {
          height: 32px;
          line-height: 32px;
          border: 1px solid #dcdee2;
          color: #409eff;
          padding: 0 10px;
          box-sizing: border-box;
          border-radius: 4px;
          cursor: pointer;
          background: #ffffff;
          &:hover {
            border-color: #409eff;
          }
        }
        .tips {
          color: #999999;
          font-size: 12px;
        }
        .changebtn {
          color: #409eff;
          cursor: pointer;
        }
        .linkIcon {
          color: #409eff;
          font-size: 16px;
        }
      }
    }
  }
}

.addbtn {
  line-height: 32px;
  text-align: center;
  border: 1px solid #dcdee2;
  color: #409eff;
  margin-bottom: 20px;
  &:hover {
    border-color: #409eff;
  }
}
</style>
