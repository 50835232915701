<template>
  <div class="plugins">
    <div>
      <div class="headsty">选择风格</div>
      <div class="box">
        <el-button type="primary" size="small" style="width: 240px"
          >修改风格</el-button
        >
        <span style="margin-left: 10px">当前：风格1</span>
        <!-- <div class="label">组件背景:</div>
				<el-radio v-model="plugins_data.style.text_style" :label="'left'">居左</el-radio>
				<el-radio v-model="plugins_data.style.text_style" :label="'center'">居中</el-radio> -->
      </div>
    </div>

    <div>
      <div class="headsty">背景设置</div>
      <div class="box">
        <div class="label">背景色</div>
        <el-color-picker
          style="margin-right: 20px"
          size="small"
          v-model="plugins_data.style.background"
        ></el-color-picker>
        <el-input
          v-model="plugins_data.style.background"
          style="width: 100px; margin-right: 6px"
        ></el-input>
      </div>
    </div>

    <div>
      <div class="headsty">选项卡样式</div>
      <div class="box">
        <div class="label">背景:</div>
        <el-color-picker
          style="margin-right: 20px"
          size="small"
          v-model="plugins_data.style.color"
        ></el-color-picker>
        <el-input
          v-model="plugins_data.style.color"
          style="width: 100px; margin-right: 6px"
        ></el-input>
      </div>
      <div class="box">
        <div class="label">标题未选:</div>
        <el-color-picker
          style="margin-right: 20px"
          size="small"
          v-model="plugins_data.style.color"
        ></el-color-picker>
        <el-input
          v-model="plugins_data.style.color"
          style="width: 100px; margin-right: 6px"
        ></el-input>
      </div>
      <div class="box">
        <div class="label">标题选中:</div>
        <el-color-picker
          style="margin-right: 20px"
          size="small"
          v-model="plugins_data.style.color"
        ></el-color-picker>
        <el-input
          v-model="plugins_data.style.color"
          style="width: 100px; margin-right: 6px"
        ></el-input>
      </div>
      <div class="box">
        <div class="label">选择条</div>
        <el-radio v-model="a1" label=""></el-radio>
        <el-radio v-model="a1" label=""></el-radio>
      </div>
      <!-- <div class="linesty"></div> -->
      <div class="box">
        <div class="label">颜色:</div>
        <el-color-picker
          style="margin-right: 20px"
          size="small"
          v-model="plugins_data.style.color"
        ></el-color-picker>
        <el-input
          v-model="plugins_data.style.color"
          style="width: 100px; margin-right: 6px"
        ></el-input>
        <el-color-picker
          style="margin-right: 20px"
          size="small"
          v-model="plugins_data.style.color"
        ></el-color-picker>
        <el-input
          v-model="plugins_data.style.color"
          style="width: 100px; margin-right: 6px"
        ></el-input>
      </div>
      <div class="box">
        <div class="label">副标题</div>
        <el-radio v-model="a1" label="1">关闭</el-radio>
        <el-radio v-model="a1" label="2">开启</el-radio>
      </div>
      <div class="box">
        <div class="label">选中背景</div>
        <el-radio v-model="a1" label="1">无</el-radio>
        <el-radio v-model="a1" label="2">背景色</el-radio>
        <el-radio v-model="a1" label="3">背景图片</el-radio>
      </div>
      <div class="box">
        <div class="label">滑动置顶</div>
        <el-radio v-model="a1" label="1">启用</el-radio>
        <el-radio v-model="a1" label="2">不启用</el-radio>
      </div>
      <div class="box">
        <div class="label">间距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">上边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_bottom"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_bottom"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_bottom"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_bottom"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">左右边距:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">上圆角:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.border_radius_top"
          :max="40"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.border_radius_top"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下圆角:</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.border_radius_bottom"
          :max="40"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.border_radius_bottom"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
    </div>

    <div class="tab-style">
      <div class="headsty">选项卡设置</div>
      <div class="box">
        <div class="item">选择</div>
        <div class="item">选择</div>
        <div class="item" style="border: 1px dashed">添加</div>
      </div>
    </div>

    <div>
      <div class="headsty">选择设置</div>
      <div class="box">
        <el-form
          :model="form"
          ref="form"
          :rules="rules"
          label-width="80px"
          :inline="false"
          size="normal"
        >
          <el-form-item label="标题">
            <el-input
              v-model="form.a1"
              maxlength="5"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="标题">
            <el-input
              v-model="form.a1"
              maxlength="5"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="tab-style">
      <div class="headsty">选择商品</div>
      <div
        class="box"
        style="display: flex; flex-direction: column; align-items: flex-start"
      >
        <el-radio-group v-model="form.a1">
          <el-radio label="1"> 手动选择 </el-radio>
          <el-radio label="2"> 选择分类 </el-radio>
          <el-radio label="3"> 选择分组 </el-radio>
        </el-radio-group>
        <div
          class="item"
          style="border: 1px dashed; width: 100%; margin-top: 10px; padding: "
        >
          添加
        </div>
      </div>
    </div>

    <div>
      <div class="headsty">选择风格</div>
      <div class="box">
        <img
          src="../../imgs/goodplugins/a1.png"
          alt=""
          style="width: 56px; height: 32px; cursor: pointer"
          :class="{ active: active === 1 }"
          @click="active = 1"
        />
        <img
          src="../../imgs/goodplugins/a2.png"
          alt=""
          style="width: 56px; height: 32px; cursor: pointer"
          :class="{ active: active === 2 }"
          @click="active = 2"
        />
        <img
          src="../../imgs/goodplugins/a3.png"
          alt=""
          style="width: 56px; height: 32px; cursor: pointer"
          :class="{ active: active === 3 }"
          @click="active = 3"
        />
        <img
          src="../../imgs/goodplugins/a4.png"
          alt=""
          style="width: 56px; height: 32px; cursor: pointer"
          :class="{ active: active === 4 }"
          @click="active = 4"
        />
        <img
          src="../../imgs/goodplugins/a5.png"
          alt=""
          style="width: 56px; height: 32px; cursor: pointer"
          :class="{ active: active === 5 }"
          @click="active = 5"
        />
        <img
          src="../../imgs/goodplugins/a6.png"
          alt=""
          style="width: 56px; height: 32px; cursor: pointer"
          :class="{ active: active === 6 }"
          @click="active = 6"
        />
        <img
          src="../../imgs/goodplugins/a7.png"
          alt=""
          style="width: 56px; height: 32px; cursor: pointer"
          :class="{ active: active === 7 }"
          @click="active = 7"
        />
      </div>
    </div>

    <div>
      <div class="headsty">样式</div>
      <div class="box">
        <div
          style="padding: 5px 30px; border: 1px solid #ccc; cursor: pointer"
          :class="{ active: active === 1 }"
          @click="active = 1"
        >
          默认
        </div>
        <div
          style="
            padding: 5px 30px;
            border: 1px solid #ccc;
            cursor: pointer;
            margin-left: -1px;
          "
          :class="{ active: active === 2 }"
          @click="active = 2"
        >
          投影
        </div>
        <div
          style="
            padding: 5px 30px;
            border: 1px solid #ccc;
            cursor: pointer;
            margin-left: -1px;
          "
          :class="{ active: active === 3 }"
          @click="active = 3"
        >
          描边
        </div>
      </div>
    </div>

    <div>
      <div class="headsty">颜色背景</div>
      <div class="box">
        <div class="label">商品背景</div>
        <el-color-picker
          style="margin-right: 20px"
          size="small"
          v-model="plugins_data.style.background"
        ></el-color-picker>
        <el-input
          v-model="plugins_data.style.background"
          style="width: 100px; margin-right: 6px"
        ></el-input>
      </div>
    </div>

    <div>
      <div class="headsty">
        显示内容(划线价和会员价同时选择时，优先显示会员价)
      </div>
      <el-checkbox-group v-model="form.a1">
        <el-checkbox label="1">
          <div class="box">
            <div class="label">商品标题</div>
            <el-color-picker
              style="margin-right: 20px"
              size="small"
              v-model="plugins_data.style.background"
            ></el-color-picker>
            <el-input
              v-model="plugins_data.style.background"
              style="width: 100px; margin-right: 6px"
            ></el-input>
          </div>
        </el-checkbox>
        <el-checkbox label="2">
          <div class="box">
            <div class="label">副标题</div>
            <el-color-picker
              style="margin-right: 20px"
              size="small"
              v-model="plugins_data.style.background"
            ></el-color-picker>
            <el-input
              v-model="plugins_data.style.background"
              style="width: 100px; margin-right: 6px"
            ></el-input>
          </div>
        </el-checkbox>
        <el-checkbox label="3">
          <div class="box">
            <div class="label">商品价格</div>
            <el-color-picker
              style="margin-right: 20px"
              size="small"
              v-model="plugins_data.style.background"
            ></el-color-picker>
            <el-input
              v-model="plugins_data.style.background"
              style="width: 100px; margin-right: 6px"
            ></el-input>
          </div>
        </el-checkbox>
        <el-checkbox label="4">
          <div class="box">
            <div class="label">划线原价</div>
            <el-color-picker
              style="margin-right: 20px"
              size="small"
              v-model="plugins_data.style.background"
            ></el-color-picker>
            <el-input
              v-model="plugins_data.style.background"
              style="width: 100px; margin-right: 6px"
            ></el-input>
          </div>
        </el-checkbox>
        <el-checkbox label="5">
          <div class="box">
            <div class="label">商品销量</div>
            <el-color-picker
              style="margin-right: 20px"
              size="small"
              v-model="plugins_data.style.background"
            ></el-color-picker>
            <el-input
              v-model="plugins_data.style.background"
              style="width: 100px; margin-right: 6px"
            ></el-input>
          </div>
        </el-checkbox>
        <el-checkbox label="6">
          <div class="box">
            <div class="label">会员价</div>
          </div>
        </el-checkbox>
        <br />
        <el-checkbox label="7">
          <div class="box">
            <div class="label">标题标签</div>
          </div>
        </el-checkbox>
      </el-checkbox-group>
    </div>

    <div>
      <div class="headsty">
        显示内容(划线价和会员价同时选择时，优先显示会员价)
      </div>
      <div class="box">
        <el-radio-group v-model="form.a1">
          <el-radio label="1">显示</el-radio>
          <el-radio label="2">隐藏</el-radio>
        </el-radio-group>
      </div>
      <div class="box">
        <el-form
          :model="form"
          ref="form"
          :rules="rules"
          label-width="80px"
          :inline="false"
          size="normal"
        >
          <el-form-item label="样式">
            <img
              src="../../imgs/goodplugins/b1.png"
              alt=""
              style="width: 32px; height: 32px; cursor: pointer"
              :class="{ active: active === 1 }"
              @click="active = 1"
            />
            <img
              src="../../imgs/goodplugins/b2.png"
              alt=""
              style="width: 32px; height: 32px; cursor: pointer"
              :class="{ active: active === 2 }"
              @click="active = 2"
            />
            <img
              src="../../imgs/goodplugins/b3.png"
              alt=""
              style="width: 32px; height: 32px; cursor: pointer"
              :class="{ active: active === 3 }"
              @click="active = 3"
            />
            <img
              src="../../imgs/goodplugins/b4.png"
              alt=""
              style="width: 32px; height: 32px; cursor: pointer"
              :class="{ active: active === 4 }"
              @click="active = 4"
            />
          </el-form-item>
          <el-form-item label="文字">
            <el-input
              v-model="form.a1"
              placeholder=""
              size="normal"
              clearable
              maxlength="4"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="颜色">
            <div style="display: flex; align-items: center">
              <el-color-picker
                style="margin-right: 20px"
                size="small"
                v-model="plugins_data.style.background"
              ></el-color-picker>
              <el-input
                v-model="plugins_data.style.background"
                style="width: 100px; margin-right: 6px"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="圆角">
            <el-slider v-model="form.a2"></el-slider>
            <el-input v-model="form.a2" placeholder="" size="normal" clearable>
              <template slot="append">px</template>
            </el-input>
          </el-form-item>
          <el-form-item label="背景">
            <el-radio-group v-model="form.a1">
              <el-radio label="1"> 跟随主题风格 </el-radio>
              <el-radio label="2"> 自定义 </el-radio>
            </el-radio-group>
            <div style="display: flex; align-items: center">
              <el-color-picker
                style="margin-right: 20px"
                size="small"
                v-model="plugins_data.style.background"
              ></el-color-picker>
              <el-input
                v-model="plugins_data.style.background"
                style="width: 100px; margin-right: 6px"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="边框">
            <div style="display: flex; align-items: center">
              <el-color-picker
                style="margin-right: 20px"
                size="small"
                v-model="plugins_data.style.background"
              ></el-color-picker>
              <el-input
                v-model="plugins_data.style.background"
                style="width: 100px; margin-right: 6px"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="尺寸">
            <el-radio-group v-model="form.a1">
              <el-radio label="1"> 小 </el-radio>
              <el-radio label="1"> 中 </el-radio>
              <el-radio label="1"> 大 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div>
      <div class="headsty">点击购物车按钮</div>
      <div class="box">
        <el-radio-group v-model="form.a1">
          <el-radio label="1">进入商品详情页</el-radio>
          <el-radio label="2">商品加购</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div>
      <div class="headsty">角标设置</div>
      <div class="box">
        <el-radio-group v-model="form.a1">
          <el-radio label="1">不显示</el-radio>
          <el-radio label="2">系统图标</el-radio>
          <el-radio label="2">自定义</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div>
      <div class="headsty">组件边距</div>
      <div class="box">
        <div class="label">商品间距</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">上边距</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边距</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">左右边距</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">间距</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">间距</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
    </div>

    <div>
      <div class="headsty">圆角设置</div>
      <div class="box">
        <div class="label">上圆角</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下圆角</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
    </div>

    <div>
      <div class="headsty">会员等级预览</div>
      <div class="box">
        <el-radio-group v-model="form.a1">
          <el-radio label="1">允许访问</el-radio>
          <el-radio label="2">禁止访问</el-radio>
        </el-radio-group>
      </div>
      <div class="box">
        <el-select
          v-model="form.a1"
          value-key=""
          placeholder="全部允许"
          clearable
          filterable
        >
          <el-option label="默认会员" value="1"> </el-option>
          <el-option label="vip5" value="2"> </el-option>
          <el-option label="VVIP" value="3"> </el-option>
        </el-select>
      </div>
      <div class="box">
        <div class="label">下圆角</div>
        <el-slider
          class="sliderstyle"
          v-model="plugins_data.style.padding_left"
          :max="50"
        ></el-slider>
        <el-input
          class="styleinput"
          type="number"
          v-model.number="plugins_data.style.padding_left"
        >
          <template slot="append">PX</template>
        </el-input>
      </div>
    </div>

    <chooselink
      :chooseShow.sync="chooseShow"
      @confirm="confirm"
      :isSpec="isSpec"
      :price="price"
    ></chooselink>
  </div>
</template>

<script>
import chooselink from "../../common/chooselink.vue";
export default {
  name: "titles",
  components: { chooselink },
  data() {
    return {
      chooseShow: false,
      form: {
        a1: "1",
        a2: 1,
      },
      active: 1,
    };
  },
  props: {
    plugins_data: { type: Object },
    isSpec: {
      type: String,
      default: "erp_goods.is_spec",
    },
    price: {
      type: String,
      default: "erp_goods.retail_price",
    },
  },
  methods: {
    confirm(e) {
      this.plugins_data.link = e;
      console.log(this.plugins_data);
    },
  },
};
</script>

<style scoped lang="less">
@import "../../style/pluginsCommon.css";
.tab-style {
  .box {
    display: flex;
    flex-direction: column;
    .item {
      width: 100%;
      border: 1px solid #f1f1f1;
      padding: 10px;
    }
  }
}
.active {
  border: 1px solid #479cf7 !important;
  z-index: 99;
}
</style>
