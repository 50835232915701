var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"pluginswiper"}},[_c('div',{staticClass:"swiper",style:({
        paddingTop: _vm.plugins_data.style.padding_top / 2 + 'px',
        paddingBottom: _vm.plugins_data.style.padding_bottom / 2 + 'px',
      })},[_c('div',{staticClass:"scroll-view_H"},[(_vm.plugins_data.coupon.length == 0)?_c('div',{staticClass:"scroll-view-item_H",style:({
            paddingLeft: _vm.plugins_data.style.padding_left + 'px',
            paddingRight: _vm.plugins_data.style.padding_left + 'px',
          })},[_c('div',{staticClass:"item-content",style:({
              background: _vm.plugins_data.style.backgroundColor,
              borderRadius:
                _vm.plugins_data.style.border_radius_top / 2 +
                'px ' +
                _vm.plugins_data.style.border_radius_top / 2 +
                'px ' +
                _vm.plugins_data.style.border_radius_bottom / 2 +
                'px ' +
                _vm.plugins_data.style.border_radius_bottom / 2 +
                'px',
            })},[_c('div',{staticClass:"item-content-left"},[_c('div',{staticClass:"item-left-bg",style:({
                  background: _vm.plugins_data.style.insetBg,
                  color: _vm.plugins_data.style.backgroundColor,
                })},[_c('i',{staticClass:"tem-bgcoin",style:({ background: _vm.plugins_data.style.backgroundColor })}),_c('div',{staticClass:"coupon-desc",style:({ background: _vm.plugins_data.style.insetBg })},[_vm._m(0)])])]),_c('div',{staticClass:"item-content-right"},[_c('div',{staticClass:"item-right-click",style:({
                  color: _vm.plugins_data.style.insetBg,
                  border: '3px solid ' + _vm.plugins_data.style.insetBg + '',
                })},[_vm._v(" 点击领取 ")])])])]):_vm._e(),_vm._l((_vm.plugins_data.coupon),function(item,index){return _c('div',{key:index,staticClass:"scroll-view-item_H",style:({
            paddingLeft: _vm.plugins_data.style.padding_left + 'px',
            paddingRight: _vm.plugins_data.style.padding_left + 'px',
          })},[_c('div',{staticClass:"item-content",style:({
              background: _vm.plugins_data.style.backgroundColor,
              borderRadius:
                _vm.plugins_data.style.border_radius_top / 2 +
                'px ' +
                _vm.plugins_data.style.border_radius_top / 2 +
                'px ' +
                _vm.plugins_data.style.border_radius_bottom / 2 +
                'px ' +
                _vm.plugins_data.style.border_radius_bottom / 2 +
                'px',
            })},[_c('div',{staticClass:"item-content-left"},[_c('div',{staticClass:"item-left-bg",style:({
                  background: _vm.plugins_data.style.insetBg,
                  color: _vm.plugins_data.style.backgroundColor,
                })},[_c('i',{staticClass:"tem-bgcoin",style:({ background: _vm.plugins_data.style.backgroundColor })}),_c('div',{staticClass:"coupon-desc",style:({ background: _vm.plugins_data.style.insetBg })},[_c('div',{staticClass:"coupon-desc-content"},[_vm._v(" "+_vm._s(item.type == 1 ? "折扣券" : "满减券")+" "),_c('div',{staticClass:"coupon-desc-text"},[(item.type == 1)?[_vm._v(" 满"+_vm._s(parseFloat(item.full))+"元享"+_vm._s(parseFloat(item.discount))+"折 ")]:[_vm._v(" 满"+_vm._s(parseFloat(item.full))+"元减"+_vm._s(parseFloat(item.discount))+"元 ")]],2)])])])]),_c('div',{staticClass:"item-content-right"},[_c('div',{staticClass:"item-right-click",style:({
                  color: _vm.plugins_data.style.insetBg,
                  border: '3px solid ' + _vm.plugins_data.style.insetBg + '',
                })},[_vm._v(" 点击领取 ")])])])])})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coupon-desc-content"},[_vm._v(" 优惠券类型 "),_c('div',{staticClass:"coupon-desc-text"},[_vm._v("优惠券功能")])])
}]

export { render, staticRenderFns }