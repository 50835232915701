var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plugins",style:({'padding':_vm.plugins_data.style.margin/2+'px 0'})},[_c('div',{staticClass:"richtext",style:({
		'padding':_vm.plugins_data.style.padding_top/2+'px '+_vm.plugins_data.style.padding_left/2+'px '+_vm.plugins_data.style.padding_bottom/2+'px',
		
	})},[_c('div',{staticClass:"content",style:({
			'background':_vm.plugins_data.style.background,
			'borderRadius':_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px',
			'padding':_vm.plugins_data.style.text_padding/2+'px'
		}),domProps:{"innerHTML":_vm._s(_vm.plugins_data.data)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }