<template>
  <div class='plugins'>
    <div>
      <div class="headsty">图标设置</div>
      <div class="box">
        <!-- <div class="label">添加图标:</div> -->
        <div class="imagebox" @click="getImage(1)">
          <el-image class="image" v-if="plugins_data.data.telIcon" :src="plugins_data.data.telIcon" fit="cover">
          </el-image>
          <div class="addbox" v-else>
            <i class="el-icon-plus addicon"></i>
            <div class="addtext">拨号图标</div>
          </div>
        </div>
        <div class="imagebox" @click="getImage(2)">
          <el-image class="image" v-if="plugins_data.data.positionIcon" :src="plugins_data.data.telIcon" fit="cover">
          </el-image>
          <div class="addbox" v-else>
            <i class="el-icon-plus addicon"></i>
            <div class="addtext">定位图标</div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="headsty">商家信息</div>
      <div class="box">
        <div class="label">营业时间:</div>
        <div style="flex: 1;">
          <el-input v-model="plugins_data.data.time" placeholder="请输入营业时间"></el-input>
        </div>
      </div>
      <div class="box">
        <div class="label">联系电话:</div>
        <div style="flex: 1;">
          <el-input v-model="plugins_data.data.phone" placeholder="请输入联系电话"></el-input>
        </div>
      </div>
      <div class="box">
        <div class="label">商家地址:</div>
        <div style="flex: 1;">
          <el-cascader style="width: 360px;" v-model="plugins_data.data.area" :options="areaTree" :props="props">
          </el-cascader>
        </div>
      </div>
      <div class="box">
        <div class="label"></div>
        <div style="flex: 1;">
          <el-input v-model="plugins_data.data.adress" placeholder="请输入商家详细地址"></el-input>
        </div>
      </div>
    </div>

    <div>
      <div class="headsty">内容样式</div>
      <div class="box">
        <div class="label">背景颜色:</div>
        <el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.background">
        </el-color-picker>
        <el-input v-model="plugins_data.style.background" style="width: 100px;margin-right: 6px;"></el-input>
        <el-button plain size="small" @click="plugins_data.style.background = '#FFFFFF'">重置</el-button>
      </div>
      <div class="box">
        <div class="label">文字颜色:</div>
        <el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.color"></el-color-picker>
        <el-input v-model="plugins_data.style.color" style="width: 100px;margin-right: 6px;"></el-input>
        <el-button plain size="small" @click="plugins_data.style.color = '#333333'">重置</el-button>
      </div>
      <div class="box">
        <div class="label">上圆角:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_top">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下圆角:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_bottom">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">上边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.padding_top" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_top">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.padding_bottom" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_bottom">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">左右边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.padding_left" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_left">
          <template slot="append">PX</template>
        </el-input>
      </div>
    </div>

    <xsk-manage :managehide.sync="imgshow" is_rest @confirm="pickimg"></xsk-manage>
  </div>
</template>

<script>
  export default {
    name: 'informationData',
    data() {
      return {
        imgshow: false,
        pickIndex: null,
        areaTree: [],
        props: {
          value: 'area_name',
          label: 'area_name',
          children: '_child'
        }
      };
    },
    props: {
      plugins_data: {
        type: Object
      },
    },
    created() {
      this.getareatree()
    },
    methods: {
      getareatree() {
        this.axios.get('/api/get_area_tree').then(res => {
          if (res.code == 200) {
            res.data.map(item => {
              if (item._child.length) {
                item._child.map(it => {
                  if (it._child.length) {
                    it._child.map(i => {
                      delete i._child
                    })
                  } else {
                    delete it._child
                  }
                })
              } else {
                delete item._child
              }
            })
            this.areaTree = res.data
          }
        })
      },
      getImage(e) {
        this.pickIndex = e
        this.imgshow = true
      },
      //选择图片
      pickimg(e) {
        this.plugins_data.data[this.pickIndex == 1 ? 'telIcon' : 'positionIcon'] = e.imgobjs[0].qiniu_url
      },
    }
  };
</script>

<style>
  #xskswiper .el-input.is-disabled .el-input__inner {
    color: #333 !important;
  }
</style>
<style lang="less" scoped>
  @import "../../style/pluginsCommon.css";

  .imagebox {
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
    margin-right: 15px;

    &:last-child {
      margin: 0px;
    }

    .image {
      width: 100%;
      height: 100%;
    }

    .addbox {
      width: 100%;
      height: 100%;
      border: 1px solid #e3e3e3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .addicon {
        font-size: 20px;
        color: #1989fa;
      }

      .addtext {
        margin-top: 4px;
        font-size: 12px;
        color: #999999;
      }
    }
  }

  .addbtn {
    line-height: 32px;
    text-align: center;
    border: 1px solid #dcdee2;
    color: #409eff;
    margin-bottom: 20px;

    &:hover {
      border-color: #409eff;
    }
  }

  .inputbox {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-cascader {
    line-height: normal;
  }
</style>