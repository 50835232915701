<!-- view窗口 -->
<template>
  <div class="box">
    <div class="listbox"
      :class="plugins_data.style.type == 1 ? 'doublelist' : plugins_data.style.type == 2 ? 'threelist' : plugins_data.style.type == 3 ? 'textlist' : 'list'"
      :style="{paddingLeft: plugins_data.style.padding_left / 2 + 'px',paddingRight: plugins_data.style.padding_left / 2 + 'px',paddingTop: plugins_data.style.padding_top / 2 + 'px',paddingBottom: plugins_data.style.padding_bottom / 2 + 'px',background: plugins_data.style.card_bg_type === 1 ? 'rgba(0,0,0,0)' : plugins_data.style.card_background}">
      <template v-if="!plugins_data.list || plugins_data.list.length == 0">
        <div class="goodsbox" v-for="(item, index) in plugins_data.style.type == 2 ? 3 : 2" :key="index"
          :style="{background: plugins_data.style.goods_background,borderRadius: plugins_data.style.border_radius_top / 2 + 'px ' + plugins_data.style.border_radius_top / 2 + 'px ' + plugins_data.style.border_radius_bottom / 2 + 'px ' + plugins_data.style.border_radius_bottom / 2 + 'px'}">
          <div class="image">
            <el-image style="width: 100%; height: 100%" :src="require('assets/icon/goods_col2.png')" fit="cover">
            </el-image>
          </div>
          <div class="rightbox">
            <div class="goodsname">这里是商品名称这里是商品名称</div>
            <div class="desc" v-if="plugins_data.style.type == 3 || plugins_data.style.type == 4">
              这里是商品简介
            </div>
            <div class="pricebox">
              <div class="price" :style="{ color: plugins_data.style.price_color }">
                ￥<span>20.00</span>
              </div>
              <div class="addcar" :class="plugins_data.button.paybtn_type == 1 ? 'paybg' : 'paytext'"
                :style="{background: plugins_data.button.paybtn_type == 1 ? plugins_data.button.background : '',color: plugins_data.button.color,borderRadius: plugins_data.button.borderRadius + 'px',border:plugins_data.button.paybtn_type == 2 ? '1px solid ' + plugins_data.button.background : '1px solid rgba(0,0,0,0)'}"
                v-if="plugins_data.button && plugins_data.button.paybtn_hidden">
                {{ plugins_data.button.text }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-for="(item, index) in plugins_data.list ? plugins_data.list : plugins_data.goods">
        <div class="goodsbox" v-if="index < plugins_data.showNumber" :key="index"
          :style="{background: plugins_data.style.goods_background,borderRadius:plugins_data.style.border_radius_top / 2 + 'px ' + plugins_data.style.border_radius_top / 2 + 'px ' + plugins_data.style.border_radius_bottom / 2 + 'px ' + plugins_data.style.border_radius_bottom / 2 + 'px'}">
          <el-image class="image" :src="item.image" fit="cover"></el-image>
          <div class="rightbox">
            <div class="goodsname">{{ item.name }}</div>
            <div class="desc" v-if="plugins_data.style.type == 3 || plugins_data.style.type == 4">
              {{ item.desc }}
            </div>
            <div class="pricebox">
              <!-- <div class="price" v-if="item[isSpec]" :style="{ color: plugins_data.style.price_color }">
                ￥<span>{{ item.min_price }}</span>~￥<span>{{ item.max_price }}</span>
              </div> -->
              <div class="price" :style="{ color: plugins_data.style.price_color }">
                ￥<span>{{ item[price] }}</span>
              </div>
              <div class="addcar paytext" :class="plugins_data.button.paybtn_type == 1 ? 'paybg' : 'paytext'"
                :style="{background: plugins_data.button.paybtn_type == 1 ? plugins_data.button.background : '',color: plugins_data.button.color,borderRadius: plugins_data.button.borderRadius + 'px',border: plugins_data.button.paybtn_type == 2 ? '1px solid ' + plugins_data.button.background : '1px solid rgba(0,0,0,0)'}"
                v-if="plugins_data.button && plugins_data.button.paybtn_hidden">
                {{ plugins_data.button.text }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import {
    eltips
  } from "@/util/util.js";
  export default {
    name: "goodsgroup",
    data() {
      return {
        list: [],
      };
    },
    props: {
      plugins_data: {
        type: Object
      },
      isSpec: {
        type: String,
        default: 'erp_goods.is_spec'
      },
      price: {
        type: String,
        default: 'erp_goods.retail_price'
      }
    },
    watch: {
      plugins_data: {
        //监听的对象
        deep: true, //深度监听设置为 true
        handler: function (newV, oldV) {
          console.log("watch中2：", newV.list);
          this.getList();
          // let arr = localStorage.getItem('arr')
          // this.list = JSON.parse(JSON.stringify(arr));
      // console.log(this.list,'这次');
      // console.log(arr,'这2次');
        },
      },
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList() {
        let data = {
          page: 1,
          status: 1,
          limit: this.plugins_data.showNumber,
          sort_field: this.plugins_data.sort_field,
          sort_type: this.plugins_data.sort_type,
          // limit: 200,
        };
        let type = this.plugins_data.goods_option.type;
        console.log(this.plugins_data,'this.plugins_data');
        // 1、自选商品 2、分类商品 3、分组商品 4、营销属性
        if (type == 1) {
          data.ids = "";
          let goods = this.plugins_data.list ?
            this.plugins_data.list :
            this.plugins_data.goods;
          if (goods.length > 0) {
            let str = [];
            goods.forEach((item, index) => {
              if (index < this.plugins_data.showNumber) {
                str.push(item.id);
              }
            });
            data.ids = str.join(",");
            // const cachedArray = localStorage.getItem('arr');
            // data.ids = cachedArray
          } else {
            this.list = [];
            return;
          }
        }
        if (type == 2) {
          if (this.plugins_data.category.length > 0) {
            data.category_id = this.plugins_data.category[0].id;
          }
        }
        if (type == 3) {
          if (this.plugins_data.group.length > 0) {
            data.group_id = this.plugins_data.group[0].id;
          }
        }
        if (type == 4) {
          data.marketing_label = this.plugins_data.marketing_label;
        }
        let url = "/api/store/goods/list"
        let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
        if (userinfo.roles.length > 0) {
          if (userinfo.roles[0].id == 97 || userinfo.roles[0].id == 92) {
            url = '/api/ecshop/goods/list'
          }
        }
        this.axios.get(url, {
          params: data
        }).then((res) => {
          this.list = res.data.data;
  
        });
      },
    },
  };
</script>

<style scoped lang="less">
  div {
    box-sizing: border-box !important;
  }

  .box {
    margin: 0 auto;
    width: 373px;
  }

  .listbox {
    width: 373px;
    overflow: hidden;

    .goodsbox {
      margin-bottom: 8px;
      margin-right: 8px;
      overflow: hidden;

      .image {
        display: block;
        width: 100%;
        height: 170px;
      }
    }

    .rightbox {
      position: relative;
      padding: 6px 8px;

      .goodsname {
        margin-bottom: 6px;
        font-size: 14px;
        line-height: 18px;
      }

      .pricebox {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
          font-size: 12px;

          span {
            font-weight: bold;
          }
        }

        .addcar {
          cursor: pointer;
          position: relative;
          line-height: 20px;
          font-size: 12px;
          transform: scale(0.8);
          padding: 0 4px;
          border-radius: 2px;
          // right: -4px;
        }

        .paybg {
          background: #ff0000;
          color: #ffffff;
        }

        .paytext {
          background: #ffffff;
          color: #ff0000;
          border: 1px solid #ff0000;
        }
      }
    }
  }

  .doublelist {
    .goodsbox {
      vertical-align: top;
      display: inline-block;
      width: calc(50% - 4px);

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    .goodsname {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .threelist {
    .goodsbox {
      display: inline-block;
      width: 32%;
      margin-right: 2%;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .image {
        height: 110px;
      }
    }

    .goodsname {
      height: 36px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  .textlist {
    .goodsbox {
      display: flex;
      padding: 8px 12px;
      margin-bottom: 0;
      margin-right: 0;
      border-bottom: 1px solid #f3f3f3;

      .image {
        flex: 0 0 110px;
        width: 110px;
        height: 110px;
      }

      .rightbox {
        flex: 1;
        position: relative;
        padding: 0;
        margin-left: 16px;

        .desc {
          font-size: 12px;
          color: #999999;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }

        .pricebox {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0px;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }

  .list {
    .goodsbox {
      margin-right: 0;

      .image {
        height: 160px;
      }
    }

    .rightbox {
      .desc {
        font-size: 12px;
        color: #999999;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
</style>