//全局组件注册

//基础组件
import goodsgroup_view from './view/baseplugins/goodsgroup.vue' //商品组view
import goodsgroup_data from './data/baseplugins/goodsgroup.vue' //商品组data
import tabs_view from './view/baseplugins/tabs.vue' //标签页view
import tabs_data from './data/baseplugins/tabs.vue' //标签页data

import titles_view from './view/baseplugins/titles.vue' //标题栏view
import titles_data from './data/baseplugins/titles.vue' //标题栏data

import search_view from './view/baseplugins/search.vue' //搜索栏view
import search_data from './data/baseplugins/search.vue' //搜索栏data

import toppingSearch_view from './view/baseplugins/toppingSearch.vue' //置顶搜索栏view
import toppingSearch_data from './data/baseplugins/toppingSearch.vue' //置顶搜索栏data

import swiper_view from './view/baseplugins/swiper.vue' //图片轮播view
import swiper_data from './data/baseplugins/swiper.vue' //图片轮播data

import picture_view from './view/baseplugins/picture.vue' //单图组view
import picture_data from './data/baseplugins/picture.vue' //单图组data
import menus_view from './view/baseplugins/menus.vue' //按钮组view
import menus_data from './data/baseplugins/menus.vue' //按钮组data
import imageExhibition_view from './view/baseplugins/imageExhibition.vue' //图片展播view
import imageExhibition_data from './data/baseplugins/imageExhibition.vue' //图片展播data
import guide_view from './view/baseplugins/guide.vue' //辅助线view
import guide_data from './data/baseplugins/guide.vue' //辅助线data
import richtext_view from './view/baseplugins/richtext.vue' //富文本view
import richtext_data from './data/baseplugins/richtext.vue' //富文本data
import magic_view from './view/baseplugins/magic.vue' //魔方view
import magic_data from './data/baseplugins/magic.vue' //魔方data
import video_view from './view/baseplugins/video.vue' //视频组件view
import video_data from './data/baseplugins/video.vue' //视频组件data
import graphic_view from './view/baseplugins/graphic.vue' //图文列表view
import graphic_data from './data/baseplugins/graphic.vue' //图文列表data
import notice_view from './view/baseplugins/notice.vue' //公告view
import notice_data from './data/baseplugins/notice.vue' //公告data 
import hotarea_view from './view/baseplugins/hotarea.vue' //热区view
import hotarea_data from './data/baseplugins/hotarea.vue' //热区data
import coupon_view from './view/baseplugins/coupon.vue' //优惠券view
import coupon_data from './data/baseplugins/coupon.vue' //优惠券data

//营销组件
import newPeople_view from './view/marketPlugins/newPeople.vue' //新人专享view
import newPeople_data from './data/marketPlugins/newPeople.vue' //新人专享data
import seckill_view from './view/marketPlugins/seckill.vue' //秒杀抢购view
import seckill_data from './data/marketPlugins/seckill.vue' //秒杀抢购data
import special_view from './view/marketPlugins/special.vue' //特价商品view
import special_data from './data/marketPlugins/special.vue' //特价商品data

//额外组件
import storechoose_view from './view/otherPlugins/storechoose.vue' //门店选择view
import storechoose_data from './data/otherPlugins/storechoose.vue' //门店选择data
//启动广告
import launchads_view from './view/otherPlugins/launchads.vue' //启动广告view
import launchads_data from './data/otherPlugins/launchads.vue' //启动广告data

function retailplugins(Vue) {
	Vue.component("retail-goodsgroup-view", goodsgroup_view)
	Vue.component("retail-goodsgroup-data", goodsgroup_data)

	Vue.component("retail-tabs-view", tabs_view)
	Vue.component("retail-tabs-data", tabs_data)

	Vue.component("retail-titles-view", titles_view)
	Vue.component("retail-titles-data", titles_data)

	Vue.component("retail-search-view", search_view)
	Vue.component("retail-search-data", search_data)

	Vue.component("retail-toppingSearch-view", toppingSearch_view)
	Vue.component("retail-toppingSearch-data", toppingSearch_data)
	
	Vue.component("retail-swiper-view", swiper_view)
	Vue.component("retail-swiper-data", swiper_data)
	Vue.component("retail-picture-view", picture_view)
	Vue.component("retail-picture-data", picture_data)
	Vue.component("retail-imageExhibition-view", imageExhibition_view)
	Vue.component("retail-imageExhibition-data", imageExhibition_data)
	Vue.component("retail-menus-view", menus_view)
	Vue.component("retail-menus-data", menus_data)
	Vue.component("retail-guide-view", guide_view)
	Vue.component("retail-guide-data", guide_data)
	Vue.component("retail-richtext-view", richtext_view)
	Vue.component("retail-richtext-data", richtext_data)
	Vue.component("retail-magic-view", magic_view)
	Vue.component("retail-magic-data", magic_data)
	Vue.component("retail-video-view", video_view)
	Vue.component("retail-video-data", video_data)
	Vue.component("retail-graphic-view", graphic_view)
	Vue.component("retail-graphic-data", graphic_data)
	Vue.component("retail-notice-view", notice_view)
	Vue.component("retail-notice-data", notice_data)
	Vue.component("retail-newPeople-view", newPeople_view)
	Vue.component("retail-newPeople-data", newPeople_data)
	Vue.component("retail-storechoose-view", storechoose_view)
	Vue.component("retail-storechoose-data", storechoose_data)
	Vue.component("retail-seckill-view", seckill_view)
	Vue.component("retail-seckill-data", seckill_data)
	Vue.component("retail-special-view", special_view)
	Vue.component("retail-special-data", special_data)
	Vue.component("retail-hotarea-view", hotarea_view)
	Vue.component("retail-hotarea-data", hotarea_data)
	Vue.component("retail-coupon-view", coupon_view)
	Vue.component("retail-coupon-data", coupon_data)
	Vue.component("retail-launchads-view", launchads_view)
	Vue.component("retail-launchads-data", launchads_data)



}

export default retailplugins