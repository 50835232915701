<!-- 
judge 是否开启多选 true开启 
dialogVisible 对话框显现 隐藏
@confirm 返回参数
head 表头参数
choose 数据回填 
 -->


<template>
	<div id="xskPopup">
		
		<el-dialog title="选择" :visible.sync="dialogVisible" width="45%">
		
			<!-- <el-form style="width: 100%;" >
		
				<el-form-item>
					<div>
						<el-input v-model="name" placeholder="请输入" style="width: 200px;margin-right: 10px;"
							clearable></el-input>
						<el-button type="primary" size="small" plain @click='search()'>搜索</el-button>
					</div>
				</el-form-item>
		
			</el-form> -->
		
		
			<div class="dialogtabel">
				<el-table v-loading="loadings" :data="list" style="width: 100%;overflow: scroll;" stripe :header-cell-style="{ background: '#fafafa', color: '#333333'}">
		            
					<el-table-column  v-for="(item,index) of head" :key="index" :label="item.name" :prop="item.value" align="center"></el-table-column>
					
					<el-table-column label="操作" width="80" align="center">
						<template slot-scope="scope">
		
							<div class="choosebtn not-select"
								:class="{'choose':scope.row.id==chooses.id}" @click="choosegoods(scope.row)">
								{{scope.row.id==chooses.id?'已选':'选择'}}
							</div>
		
						</template>
					</el-table-column>
		
				</el-table>
		
				<div class="pages" v-if="total > 10">
					<el-pagination background :hide-on-single-page="true" @current-change="changepage"
						:current-page="page" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
		
			</div>
		
			<span slot="footer" class="dialog-footer">
				<el-button @click="close()">取 消</el-button>
				<el-button type="primary" @click="confirm()">确 定</el-button>
			</span>
			
		</el-dialog>
		
		
	</div>
</template>

<script>
	import {eltips} from '@/util/util.js'
	export default {
		name: 'xskPopup',
		props:{
			list:{
				type:Array,
				default:[]
			},
			judge:{
				type:Boolean,
				default:false
			},
			dialogVisible:{
				type:Boolean,
				default:false
			},
			head:{
				type:Array,
				default:[]
			},
			page:{
				type:Number,
				default:1
			},
			total:{
				type:Number,
				default:0
			},
			choose:{
				default:''
			}
		},
		data() {
			return {
				
				loadings:false,
				chooses:''
			};
		},
		created() {
			this.chooses=this.choose
		},
		methods: {
			confirm(){
				this.$emit('confirm',this.chooses)
			},
			// 单选
			choosegoods(item){
				this.chooses=item
			},
			// 分页
			changepage(e){
				console.log('追',e)
				this.$emit('changepage',e)
			},
			close(){
				this.$emit('close')
			}
		}
	}
</script>

<style lang="less" scoped>
	#xskPopup {
		min-height: calc(100vh - 110px);
	}
	
	.dialogtabel {
	
		// height: 400px;
		.icon {
			display: inline-block;
			vertical-align: middle;
			font-size: 12px;
			width: 20px;
			line-height: 20px;
			text-align: center;
			margin-right: 6px;
		}
	
		.xu {
			background-color: #e6fff9;
			color: #00c5c5;
		}
	
		.shi {
			background-color: #f0faff;
			color: #2d8cf0;
		}
	
		.name {
			width: 394px;
			vertical-align: top;
			display: inline-block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	
		.choosebtn {
			width: 60px;
			line-height: 32px;
			border-radius: 2px;
			color: #2d8cf0;
			border: 1px solid #c3c3c3;
			cursor: pointer;
	
			&:hover {
				border-color: #2d8cf0;
			}
		}
	
		.choose {
			background: #2d8cf0;
			color: #FFFFFF;
			border-color: #2d8cf0;
		}
	}
	
	
</style>
