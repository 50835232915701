<template>
  <div class="bodybox" :style="{
      height: 'calc(' + height + ' - ' + plugins_data.style.top/2 + 'px)',
    }">
    <template v-if="plugins_data.cofig.style==1">
      <div class="menubox" ref="ele" :style="{
          left: plugins_data.style.padding_left/2 + 'px',
          right: plugins_data.style.padding_left /2+ 'px',
          padding:
            plugins_data.style.padding_top / 2 +'px ' +
            plugins_data.style.padding_left / 2 +'px ' +
            plugins_data.style.padding_bottom / 2 +'px',
          backgroundColor: plugins_data.style.background,
          borderRadius:
            plugins_data.style.border_radius_top / 2 +'px ' +
            plugins_data.style.border_radius_top / 2 +'px ' +
            plugins_data.style.border_radius_bottom / 2 +'px ' +
            plugins_data.style.border_radius_bottom / 2 +'px',
        }">
        <div class="box" :style="{ width: 100 / plugins_data.cofig.menu_number + '%' }"
          v-for="(item, index) in plugins_data.menus" :key="index">
          <el-image class="image" :style="{
              borderRadius:
                plugins_data.cofig.menu_style == 'circular' ? '50%' : '8px',
            }" :src="item.images || require('assets/images/not_menu.png')" fit="cover"></el-image>
          <div class="text" :style="{ color: plugins_data.style.color }">
            {{ item.text }}
          </div>
        </div>
      </div>
    </template>
    <template v-if="plugins_data.cofig.style==2">
      <div class="menuboxTwo" ref="ele" :style="{
          marginLeft: plugins_data.style.padding_left/2 + 'px',
          marginRight: plugins_data.style.padding_left /2+ 'px',
          padding: plugins_data.style.padding_top / 2 +'px ' + plugins_data.style.padding_left / 2 +'px ' + plugins_data.style.padding_bottom / 2 +'px',
          backgroundColor: plugins_data.style.background,
          borderRadius: plugins_data.style.border_radius_top / 2 +'px ' + plugins_data.style.border_radius_top / 2 +'px ' + plugins_data.style.border_radius_bottom / 2 +'px ' + plugins_data.style.border_radius_bottom / 2 +'px',
        }">
        <div class="box" :style="{ width: 100 / plugins_data.cofig.menu_number + '%' }"
          v-for="(item, index) in plugins_data.menus" :key="index">
          <el-image class="image" :style="{ borderRadius: plugins_data.cofig.menu_style == 'circular' ? '50%' : '8px'}"
            :src="item.images || require('assets/images/not_menu.png')" fit="cover"></el-image>
          <div class="text" :style="{ color: plugins_data.style.color }">
            {{ item.text }}
          </div>
        </div>
      </div>
    </template>
    <template v-if="plugins_data.cofig.style==3">
      <div class="menuboxThree" ref="ele" :style="{
          marginLeft: plugins_data.style.padding_left/2 + 'px',
          marginRight: plugins_data.style.padding_left /2+ 'px',
          padding: plugins_data.style.padding_top / 2 +'px ' + plugins_data.style.padding_left / 2 +'px ' + plugins_data.style.padding_bottom / 2 +'px',
          backgroundColor: plugins_data.style.background,
          borderRadius: plugins_data.style.border_radius_top / 2 +'px ' + plugins_data.style.border_radius_top / 2 +'px ' + plugins_data.style.border_radius_bottom / 2 +'px ' + plugins_data.style.border_radius_bottom / 2 +'px',
        }">
        <swiper ref="mySwiper" :options="swiperOption" style="z-index: 0;">
          <swiper-slide v-for="(item,index) in page" :key="index">
            <div class="box" :style="{ width: 100 / plugins_data.cofig.menu_number + '%' }" v-for="(el, indx) in item"
              :key="indx">
              <el-image class="image"
                :style="{ borderRadius: plugins_data.cofig.menu_style == 'circular' ? '50%' : '8px'}"
                :src="el.images || require('assets/images/not_menu.png')" fit="cover"></el-image>
              <div class="text" :style="{ color: plugins_data.style.color }">
                {{ el.text }}
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </template>
  </div>
</template>

<script>
  import {
    swiper,
    swiperSlide
  } from "vue-awesome-swiper";
  import "swiper/swiper-bundle.css";
  export default {
    name: "meuns",
    components: {
      swiper,
      swiperSlide,
    },
    data() {
      return {
        width: 33.33,
        list: [],
        height: "0px",
        swiperOption: {
          autoHeight: false,
          // 显示分页
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          }
        },
        page: [],
      };
    },
    props: {
      plugins_data: {
        type: Object
      },
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.swiper;
      }
    },
    watch: {
      plugins_data: {
        handler: function () {
          this.$nextTick(() => {
            this.getPages();
            this.initHeight();
            this.swiper.allowTouchMove = false
          });
        },
        deep: true,
      },
    },
    mounted() {
      this.getPages();
      this.initHeight();
      this.swiper.allowTouchMove = false
    },
    methods: {
      initHeight() {
        //获取元素样式值,为元素ref="ele"(在样式里面写死了的高度)
        this.height = window.getComputedStyle(this.$refs.ele).height;
      },
      getPages() {
        let menus = this.plugins_data.menus
        let size = this.plugins_data.cofig.menu_number * 2
        this.page = this.getNewArray(menus, size)
      },
      getNewArray(arr, size) { // size=5，要分割的长度
        const arrNum = Math.ceil(arr.length / size, 10); // Math.ceil()向上取整的方法，用来计算拆分后数组的长度
        let index = 0; // 定义初始索引
        let resIndex = 0; // 用来保存每次拆分的长度
        const result = [];
        while (index < arrNum) {
          result[index] = arr.slice(resIndex, size + resIndex);
          resIndex += size;
          index++;
        }
        return result;
      }
    },
  };
</script>


<style scoped lang="less">
  .bodybox {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
  }

  .menubox {
    padding-top: 10px;
    position: absolute;
    bottom: 0;

    .box {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 10px;

      .image {
        width: 44px;
        height: 44px;
        display: block;
        margin: auto;
      }

      .text {
        margin-top: 4px;
        text-align: center;
        font-size: 12px;
      }
    }
  }

  .menuboxTwo {
    height: 150px;
    padding-top: 10px;
    overflow-x: auto;
    white-space: nowrap;

    .box {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 10px;

      .image {
        width: 44px;
        height: 44px;
        display: block;
        margin: auto;
      }

      .text {
        margin-top: 4px;
        text-align: center;
        font-size: 12px;
      }
    }
  }

  .menuboxThree {
    height: 150px;
    padding-top: 10px;

    .box {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 10px;

      .image {
        width: 44px;
        height: 44px;
        display: block;
        margin: auto;
      }

      .text {
        margin-top: 4px;
        text-align: center;
        font-size: 12px;
      }
    }

    .swiper-wrapper {
      height: auto;

      .swiper-slide {
				height: auto;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
      }

      .swiper-pagination-bullets{
        bottom: 0px;
      }
    }
  }
</style>