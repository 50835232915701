<!-- 
* @Description 
-->
<template>
  <CountTo
    :start-val="startVal"
    :end-val="Number(endVal)"
    :decimals="isInt(endVal)"
    separator=""
  />
</template>

<script>
import CountTo from 'vue-count-to';
export default {
  data() {
    return {};
  },
  props: {
    startVal: {
      type: [Number, String],
      default: '0'
    },
    endVal: {
      type: [Number, String],
      default: '0'
    }
  },
  components: {
    CountTo
  },
  created() {},
  methods: {
    isInt(val) {
      return Number(val) % 1 === 0 ? 0 : 2;
    }
  }
};
</script>

<style lang="less" scoped></style>
