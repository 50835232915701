var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({'padding-top':_vm.top+'rpx'})},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"tabs-content",style:({ 'background': _vm.plugins_data.headStyle.tabBackground })},_vm._l((_vm.plugins_data.tabs),function(item,i){return _c('div',{key:i,staticClass:"tabs-content-item",style:({'color':(_vm.plugins_data.tabActive == i?_vm.plugins_data.headStyle.tabActiveColor:_vm.plugins_data.headStyle.tabColor),
        'background':(_vm.plugins_data.tabActive == i?_vm.plugins_data.headStyle.tabActiveBg:'')}),on:{"click":function($event){return _vm.changeTab(i)}}},[_vm._v(" "+_vm._s(item.text)+" "),(_vm.plugins_data.tabActive == i)?_c('i',{staticClass:"tabs-item-active",style:({ 'background': _vm.plugins_data.headStyle.tabActiveColor })}):_vm._e()])}),0)]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"listbox",class:_vm.plugins_data.style[_vm.plugins_data.tabActive].type == 1
          ? 'doublelist'
          : _vm.plugins_data.style[_vm.plugins_data.tabActive].type == 2
          ? 'threelist'
          : _vm.plugins_data.style[_vm.plugins_data.tabActive].type == 3
          ? 'textlist'
          : 'list',style:({
        paddingLeft:
          _vm.plugins_data.style[_vm.plugins_data.tabActive].padding_left / 2 + 'px',
        paddingRight:
          _vm.plugins_data.style[_vm.plugins_data.tabActive].padding_left / 2 + 'px',
        paddingTop:
          _vm.plugins_data.style[_vm.plugins_data.tabActive].padding_top / 2 + 'px',
        paddingBottom:
          _vm.plugins_data.style[_vm.plugins_data.tabActive].padding_bottom / 2 +
          'px',
        background:
          _vm.plugins_data.style[_vm.plugins_data.tabActive].card_bg_type === 1
            ? 'rgba(0,0,0,0)'
            : _vm.plugins_data.style[_vm.plugins_data.tabActive].card_background,
      })},[(
          _vm.plugins_data.list.length == 0 ||
          _vm.plugins_data.list[_vm.plugins_data.tabActive].length == 0
        )?_vm._l((_vm.plugins_data.style[_vm.plugins_data.tabActive]
            .type == 2
            ? 3
            : 2),function(item,index){return _c('div',{key:index,staticClass:"goodsbox",style:({
            background:
              _vm.plugins_data.style[_vm.plugins_data.tabActive].goods_background,
            borderRadius:
              _vm.plugins_data.style[_vm.plugins_data.tabActive].border_radius_top /
                2 +
              'px ' +
              _vm.plugins_data.style[_vm.plugins_data.tabActive].border_radius_top /
                2 +
              'px ' +
              _vm.plugins_data.style[_vm.plugins_data.tabActive]
                .border_radius_bottom /
                2 +
              'px ' +
              _vm.plugins_data.style[_vm.plugins_data.tabActive]
                .border_radius_bottom /
                2 +
              'px',
          })},[_c('div',{staticClass:"image"},[_c('el-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require('assets/icon/goods_col2.png'),"fit":"cover"}})],1),_c('div',{staticClass:"rightbox"},[_c('div',{staticClass:"goodsname"},[_vm._v("这里是商品名称这里是商品名称")]),(
                _vm.plugins_data.style[_vm.plugins_data.tabActive].type == 3 ||
                _vm.plugins_data.style[_vm.plugins_data.tabActive].type == 4
              )?_c('div',{staticClass:"desc"},[_vm._v(" 这里是商品简介 ")]):_vm._e(),_c('div',{staticClass:"pricebox"},[_c('div',{staticClass:"price",style:({
                  color:
                    _vm.plugins_data.style[_vm.plugins_data.tabActive].price_color,
                })},[_vm._v(" ￥"),_c('span',[_vm._v("20.00")])]),(
                  _vm.plugins_data.style[_vm.plugins_data.tabActive].paybtn_hidden
                )?_c('div',{staticClass:"addcar",class:_vm.plugins_data.style[_vm.plugins_data.tabActive].paybtn_type == 1
                    ? 'paybg'
                    : 'paytext'},[_vm._v(" 加购 ")]):_vm._e()])])])}):_vm._e(),(_vm.plugins_data.list.length > 0)?[_vm._l((_vm.plugins_data.list[_vm.plugins_data.tabActive]),function(item,index){return [(index < _vm.plugins_data.showNumber[_vm.plugins_data.tabActive])?_c('div',{key:index,staticClass:"goodsbox",style:({
              background:
                _vm.plugins_data.style[_vm.plugins_data.tabActive].goods_background,
              borderRadius:
                _vm.plugins_data.style[_vm.plugins_data.tabActive].border_radius_top /
                  2 +
                'px ' +
                _vm.plugins_data.style[_vm.plugins_data.tabActive].border_radius_top /
                  2 +
                'px ' +
                _vm.plugins_data.style[_vm.plugins_data.tabActive]
                  .border_radius_bottom /
                  2 +
                'px ' +
                _vm.plugins_data.style[_vm.plugins_data.tabActive]
                  .border_radius_bottom /
                  2 +
                'px',
            })},[_c('el-image',{staticClass:"image",attrs:{"src":item.image,"fit":"cover"}}),_c('div',{staticClass:"rightbox"},[_c('div',{staticClass:"goodsname"},[_vm._v(_vm._s(item.name))]),(
                  _vm.plugins_data.style[_vm.plugins_data.tabActive].type == 3 ||
                  _vm.plugins_data.style[_vm.plugins_data.tabActive].type == 4
                )?_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(item.desc)+" ")]):_vm._e(),_c('div',{staticClass:"pricebox"},[(item.erp_goods.is_spec)?_c('div',{staticClass:"price",style:({
                    color:
                      _vm.plugins_data.style[_vm.plugins_data.tabActive].price_color,
                  })},[_vm._v(" ￥"),_c('span',[_vm._v(_vm._s(item.min_price))]),_vm._v("~￥"),_c('span',[_vm._v(_vm._s(item.max_price))])]):_c('div',{staticClass:"price",style:({
                    color:
                      _vm.plugins_data.style[_vm.plugins_data.tabActive].price_color,
                  })},[_vm._v(" ￥"),_c('span',[_vm._v(_vm._s(item.erp_goods.retail_price))])]),(
                    _vm.plugins_data.style[_vm.plugins_data.tabActive].paybtn_hidden
                  )?_c('div',{staticClass:"addcar paytext",class:_vm.plugins_data.style[_vm.plugins_data.tabActive].paybtn_type ==
                    1
                      ? 'paybg'
                      : 'paytext'},[_vm._v(" 加购 ")]):_vm._e()])])],1):_vm._e()]})]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }