var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bodybox",style:({
    height: 'calc(' + _vm.height + ' - ' + _vm.plugins_data.style.top/2 + 'px)',
  })},[(_vm.plugins_data.cofig.style==1)?[_c('div',{ref:"ele",staticClass:"menubox",style:({
        left: _vm.plugins_data.style.padding_left/2 + 'px',
        right: _vm.plugins_data.style.padding_left /2+ 'px',
        padding:
          _vm.plugins_data.style.padding_top / 2 +'px ' +
          _vm.plugins_data.style.padding_left / 2 +'px ' +
          _vm.plugins_data.style.padding_bottom / 2 +'px',
        backgroundColor: _vm.plugins_data.style.background,
        borderRadius:
          _vm.plugins_data.style.border_radius_top / 2 +'px ' +
          _vm.plugins_data.style.border_radius_top / 2 +'px ' +
          _vm.plugins_data.style.border_radius_bottom / 2 +'px ' +
          _vm.plugins_data.style.border_radius_bottom / 2 +'px',
      })},_vm._l((_vm.plugins_data.menus),function(item,index){return _c('div',{key:index,staticClass:"box",style:({ width: 100 / _vm.plugins_data.cofig.menu_number + '%' })},[_c('el-image',{staticClass:"image",style:({
            borderRadius:
              _vm.plugins_data.cofig.menu_style == 'circular' ? '50%' : '8px',
          }),attrs:{"src":item.images || require('assets/images/not_menu.png'),"fit":"cover"}}),_c('div',{staticClass:"text",style:({ color: _vm.plugins_data.style.color })},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),0)]:_vm._e(),(_vm.plugins_data.cofig.style==2)?[_c('div',{ref:"ele",staticClass:"menuboxTwo",style:({
        marginLeft: _vm.plugins_data.style.padding_left/2 + 'px',
        marginRight: _vm.plugins_data.style.padding_left /2+ 'px',
        padding: _vm.plugins_data.style.padding_top / 2 +'px ' + _vm.plugins_data.style.padding_left / 2 +'px ' + _vm.plugins_data.style.padding_bottom / 2 +'px',
        backgroundColor: _vm.plugins_data.style.background,
        borderRadius: _vm.plugins_data.style.border_radius_top / 2 +'px ' + _vm.plugins_data.style.border_radius_top / 2 +'px ' + _vm.plugins_data.style.border_radius_bottom / 2 +'px ' + _vm.plugins_data.style.border_radius_bottom / 2 +'px',
      })},_vm._l((_vm.plugins_data.menus),function(item,index){return _c('div',{key:index,staticClass:"box",style:({ width: 100 / _vm.plugins_data.cofig.menu_number + '%' })},[_c('el-image',{staticClass:"image",style:({ borderRadius: _vm.plugins_data.cofig.menu_style == 'circular' ? '50%' : '8px'}),attrs:{"src":item.images || require('assets/images/not_menu.png'),"fit":"cover"}}),_c('div',{staticClass:"text",style:({ color: _vm.plugins_data.style.color })},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),0)]:_vm._e(),(_vm.plugins_data.cofig.style==3)?[_c('div',{ref:"ele",staticClass:"menuboxThree",style:({
        marginLeft: _vm.plugins_data.style.padding_left/2 + 'px',
        marginRight: _vm.plugins_data.style.padding_left /2+ 'px',
        padding: _vm.plugins_data.style.padding_top / 2 +'px ' + _vm.plugins_data.style.padding_left / 2 +'px ' + _vm.plugins_data.style.padding_bottom / 2 +'px',
        backgroundColor: _vm.plugins_data.style.background,
        borderRadius: _vm.plugins_data.style.border_radius_top / 2 +'px ' + _vm.plugins_data.style.border_radius_top / 2 +'px ' + _vm.plugins_data.style.border_radius_bottom / 2 +'px ' + _vm.plugins_data.style.border_radius_bottom / 2 +'px',
      })},[_c('swiper',{ref:"mySwiper",staticStyle:{"z-index":"0"},attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.page),function(item,index){return _c('swiper-slide',{key:index},_vm._l((item),function(el,indx){return _c('div',{key:indx,staticClass:"box",style:({ width: 100 / _vm.plugins_data.cofig.menu_number + '%' })},[_c('el-image',{staticClass:"image",style:({ borderRadius: _vm.plugins_data.cofig.menu_style == 'circular' ? '50%' : '8px'}),attrs:{"src":el.images || require('assets/images/not_menu.png'),"fit":"cover"}}),_c('div',{staticClass:"text",style:({ color: _vm.plugins_data.style.color })},[_vm._v(" "+_vm._s(el.text)+" ")])],1)}),0)}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }