<template>
  <div class="functionContent"
    :style="{'margin':plugins_data.style.margin_top/2+'px '+ plugins_data.style.margin_left/2+'px','borderRadius': plugins_data.style.border_radius_top/2+'px ' + plugins_data.style.border_radius_top/2+'px ' + plugins_data.style.border_radius_bottom/2+'px ' + plugins_data.style.border_radius_bottom/2+'px'}">
    <div class="headbox">
      <div class="title">功能中心</div>
    </div>
    <div class="btnbox">
      <div class="box" :style="{'width':100/plugins_data.cofig.menu_number+'%'}"
        v-for="(item,key) in plugins_data.menus" :key="key">
		<div class="" :style="[aa(key,plugins_data.cofig.menu_number)]" style="text-align: center;">
			<img class="icon" style="margin-left: auto;margin-right: auto;" :src="item.url||item.defaultUrl" />
			<div class="text">{{item.text}}</div>
		</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      plugins_data: {
        type: Object
      },
    },
    watch: {
      plugins_data: {
        //监听的对象
        deep: true, //深度监听设置为 true
        handler: function (newV, oldV) {
          console.log("watch中：", newV);
        },
      },
    },
	methods:{
		aa(index,num){
			if((index+1)%num==0){
				return {'align-self': 'flex-end'}
			}
			if((index)%num==0){
				return {'align-self': 'flex-start'}
			}
			// if((index+1)%num==0){
			// 	return 'align-self: flex-start;'
			// }
			// return 'align-self: flex-start;'
		}
	}
  };
</script>

<style lang="scss" scoped>
  .functionContent {
    padding: 15px 18px 0;
    background-color: #ffffff;

    .headbox {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 14px;
        color: #333333;
        font-weight: bold;
      }
    }

    .btnbox {
      margin-top: 15px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;

      .box {
        // flex: 0 0 60px;
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // margin-right: 24px;
        margin-bottom: 15px;

        &:nth-child(4n) {
          margin-right: 0;
        }

        .icon {
          width: 48px;
          height: 48px;
          display: block;
        }

        .text {
          text-align: center;
          width: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 4px;
          color: #333333;
          font-size: 12px;
        }
      }
    }
  }
</style>