<!--
areahide  是否隐藏区  true隐藏  false不隐藏  默认false
@confirm 父组件获取回传信息 

插件依赖 element-ui
线上客Saas组件库 省市区选择器 --- 2020.09.28  
 -->

<template>
  <div id="xskAddpick">
    <el-cascader style="width: 100%;" ref="myCascader" :disabled="disabled" v-model="city" :show-all-levels="is_levels"
      clearable placeholder-style="color:#000" :placeholder="placeholder || '请选择'" :props="addprops" :options="options"
      @change="changeaddress"></el-cascader>
  </div>
</template>

<script>
export default {
  name: 'xskAddpick',
  data() {
    let _self = this;
    return {
      city: _self.areas,
      options: [],
      addprops: {
        // value:'value',
        // label:'name',
        // lazy:true,
        value: 'label',
        multiple: this.multiple,
        checkStrictly: this.checkStrictly,
        emitPath: this.emitPath,
        // lazyLoad(node,resolve){
        // 	if(_self.options.length==0){
        // 		return
        // 	}
        // 	let pid = node.data.id
        // 	let level = node.level
        // 	let levelhide = 3
        // 	if(_self.areahide){
        // 		levelhide = 2
        // 	}
        // 	if(level<levelhide){
        // 		_self.axios.get('/api/get_area', { params: {pid:pid}}).then(res => {
        // 			if (res.code == 200) {
        // 				res.data.forEach(item=>{
        // 					item.leaf = level >= levelhide-1;
        // 					if(item.level==3&&!_self.is_qu&&item.name.length>2&&(item.name.indexOf('区')!=-1)){
        // 						item.disabled = true
        // 					}
        // 				})
        // 				resolve(res.data)
        // 			} else {
        // 				return []
        // 				eltips(res.msg, 'error');
        // 			}
        // 		}).catch();
        // 	}
        // }
      }
    };
  },
  props: {
    areas: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 是否隐藏 区
    areahide: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '',
    },
    is_qu: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    emitPath: {
      type: Boolean,
      default: true,
    },
    is_levels: {
      type: Boolean,
      default: true
    },
  },
  created() {
    this.initaddress()
  },
  watch: {
    areahide(val) {
      this.initaddress()
    }
  },
  methods: {
    //获取省份列表
    initaddress() {
      // this.axios.get('/api/get_area', { params: {level:1} }).then(res => {
      // 	if (res.code == 200) {
      // 		this.options = res.data;
      // 	}
      // })
      let that = this;
      this.axios.get('/api/get_area_tree').then(res => {
        this.options = res.data
        this.options.forEach((e, i) => {
          e.label = e.area_name
          e.value = e.id,
            e.children = e._child
          for (let i = 0; i < e.children.length; i++) {
            let e1 = e.children[i]
            e1.label = e1.area_name
            e1.value = e1.id
            if (!that.areahide) {
              e1.children = e1._child
              e1.children.forEach((e2, i2) => {
                e2.label = e2.area_name
                e2.value = e2.id
                if (e2.level == 3 && !that.is_qu && e2.area_name.length > 2 && (e2.area_name.indexOf('区') != -1)) {
                  e2.disabled = true
                }
              })
            }
          }
        })
        if (this.values) {
          this.city = this.values
        }
      })
    },
    //获取插件返回信息
    changeaddress(e) {
      setTimeout(() => {
        let data
        if (e.length == 0) {
          data = false
        } else {
          if (!this.multiple) {
            // let labels = this.$refs['myCascader'].inputValue
            console.log(this.$refs['myCascader'].getCheckedNodes()[0].pathNodes)
            // labels = labels.split('/')
            // labels[0] = labels[0].split(' ')[0]
            let labels = this.$refs['myCascader'].getCheckedNodes()[0].pathNodes
            data = {
              obj: {
                province: {
                  label: labels[0].data.area_name,
                  value: labels[0].data.id,
                  code: labels[0].data.area_code,
                }
              },
              labes: labels[0].data.area_name,
              labesvalue: e
            }

            if (labels.length > 1) {
              // labels[1] = labels[1].split(' ')[1]
              data.obj.city = {
                label: labels[1].data.area_name,
                value: labels[1].data.id,
                code: labels[1].data.area_code,
              }
              data.labes = labels[0].data.area_name + labels[1].data.area_name
            }

            if (!this.areahide && labels.length == 3) {
              // labels[2] = labels[2].split(' ')[1]
              data.obj.area = {
                // label:labels[2],
                // value:e[2],
                label: labels[2].data.area_name,
                value: labels[2].data.id,
                code: labels[2].data.area_code,
              }
              // data.labes = labels[0]+labels[1]+labels[2]
              data.labes = labels[0].data.area_name + labels[1].data.area_name + labels[2].data.area_name
            }
          } else {
            data = this.city
          }
        }
        this.$emit('confirm', data)
      }, 1)

    },
  }
};
</script>

<style>
#xskAddpick .el-icon-circle-close {
  top: 0px;
}
</style>
<style scoped lang="less">
#xskAddpick {
  display: inline-block;
}
</style>
