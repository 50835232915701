var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
		'margin-top':_vm.plugins_data.style.margin_top/2+'px',
		'margin-bottom':_vm.plugins_data.style.margin_bottom/2+'px',
		'margin-left':_vm.plugins_data.style.margin_right/2+'px',
		'margin-right':_vm.plugins_data.style.margin_right/2+'px',
	}),attrs:{"id":"magic"}},[(_vm.plugins_data.type==1)?_c('div',{staticClass:"styleList"},[_c('div',{staticClass:"box",style:({'margin-right':_vm.plugins_data.style.padding/2+'px'})},[(_vm.plugins_data.data[0].image)?_c('el-image',{staticClass:"img",style:({
				borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
			}),attrs:{"src":_vm.plugins_data.data[0].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("宽度375PX")])],1),_c('div',{staticClass:"box"},[(_vm.plugins_data.data[1].image)?_c('el-image',{staticClass:"img",style:({
				borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
			}),attrs:{"src":_vm.plugins_data.data[1].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("宽度375PX")])],1)]):_vm._e(),(_vm.plugins_data.type==2)?_c('div',{staticClass:"styleList"},[_c('div',{staticClass:"box",style:({'margin-right':_vm.plugins_data.style.padding/2+'px'})},[(_vm.plugins_data.data[0].image)?_c('el-image',{staticClass:"img",style:({
				borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
			}),attrs:{"src":_vm.plugins_data.data[0].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("宽度250PX")])],1),_c('div',{staticClass:"box",style:({'margin-right':_vm.plugins_data.style.padding/2+'px'})},[(_vm.plugins_data.data[1].image)?_c('el-image',{staticClass:"img",style:({
				borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
			}),attrs:{"src":_vm.plugins_data.data[1].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("宽度250PX")])],1),_c('div',{staticClass:"box"},[(_vm.plugins_data.data[2].image)?_c('el-image',{staticClass:"img",style:({
				borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
			}),attrs:{"src":_vm.plugins_data.data[2].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("宽度250PX")])],1)]):_vm._e(),(_vm.plugins_data.type==3)?_c('div',{staticClass:"styleList styleHeight"},[_c('div',{staticClass:"left",style:({'margin-right':_vm.plugins_data.style.padding/2+'px'})},[(_vm.plugins_data.data[0].image)?_c('el-image',{staticClass:"img",style:({
				borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
			}),attrs:{"src":_vm.plugins_data.data[0].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("375*750像素")])],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"smallbox",staticStyle:{"border-right":"none"},style:({'margin-bottom':_vm.plugins_data.style.padding/2+'px'})},[(_vm.plugins_data.data[1].image)?_c('el-image',{staticClass:"img",style:({
					borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
				}),attrs:{"src":_vm.plugins_data.data[1].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("375*375像素")])],1),_c('div',{staticClass:"smallbox"},[(_vm.plugins_data.data[2].image)?_c('el-image',{staticClass:"img",style:({
					borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
				}),attrs:{"src":_vm.plugins_data.data[2].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("375*375像素")])],1)])]):_vm._e(),(_vm.plugins_data.type==4)?_c('div',{staticClass:"styleList styleHeight styleFour"},[_c('div',{staticClass:"left",style:({'margin-bottom':_vm.plugins_data.style.padding/2+'px'})},[(_vm.plugins_data.data[0].image)?_c('el-image',{staticClass:"img",style:({
				borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
			}),attrs:{"src":_vm.plugins_data.data[0].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("750*375像素")])],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"smallbox",style:({'margin-right':_vm.plugins_data.style.padding/2+'px'})},[(_vm.plugins_data.data[1].image)?_c('el-image',{staticClass:"img",style:({
					borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
				}),attrs:{"src":_vm.plugins_data.data[1].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("375*375像素")])],1),_c('div',{staticClass:"smallbox"},[(_vm.plugins_data.data[2].image)?_c('el-image',{staticClass:"img",style:({
					borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
				}),attrs:{"src":_vm.plugins_data.data[2].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("375*375像素")])],1)])]):_vm._e(),(_vm.plugins_data.type==5)?_c('div',{staticClass:"styleList styleHeight"},[_c('div',{staticClass:"left",style:({'margin-right':_vm.plugins_data.style.padding/2+'px'})},[(_vm.plugins_data.data[0].image)?_c('el-image',{staticClass:"img",style:({
				borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
			}),attrs:{"src":_vm.plugins_data.data[0].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("375*750像素")])],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"smallbox",staticStyle:{"border-right":"none"},style:({'margin-bottom':_vm.plugins_data.style.padding/2+'px'})},[(_vm.plugins_data.data[1].image)?_c('el-image',{staticClass:"img",style:({
					borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
				}),attrs:{"src":_vm.plugins_data.data[1].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("375*375像素")])],1),_c('div',{staticClass:"bottombox"},[_c('div',{staticClass:"smallbox",staticStyle:{"border-bottom":"none"},style:({'margin-right':_vm.plugins_data.style.padding/2+'px'})},[(_vm.plugins_data.data[2].image)?_c('el-image',{staticClass:"img",style:({
						borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
					}),attrs:{"src":_vm.plugins_data.data[2].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("188*375像素")])],1),_c('div',{staticClass:"smallbox"},[(_vm.plugins_data.data[3].image)?_c('el-image',{staticClass:"img",style:({
						borderRadius:_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_top/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px '+_vm.plugins_data.style.radius_bottom/2+'px',
					}),attrs:{"src":_vm.plugins_data.data[3].image,"fit":"cover"}}):_c('div',{staticClass:"textStyle"},[_vm._v("188*375像素")])],1)])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }