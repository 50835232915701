var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
	'padding':_vm.plugins_data.style.padding_top/2+'px '+_vm.plugins_data.style.padding_left/2+'px '+_vm.plugins_data.style.padding_bottom/2+'px'
	}),attrs:{"id":"businessView"}},[(_vm.list.length==0)?[_c('div',{staticClass:"cardbox",style:({
			'background':_vm.plugins_data.style.background,
			'borderRadius':_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px',
			})},[_c('div',{staticClass:"storebox"},[_c('el-image',{class:{'logo':_vm.plugins_data.data.storeStyle==1,'logo2':_vm.plugins_data.data.storeStyle==2},style:({'border-radius':_vm.plugins_data.data.logoType==1?'50%':'6px'}),attrs:{"src":require('assets/images/shopping/business.png'),"fit":"cover"}}),_c('div',{staticClass:"infobox",style:(_vm.plugins_data.data.storeStyle==2?'height:60px':'')},[_c('div',{staticClass:"name"},[_vm._v("店铺名称")]),(_vm.plugins_data.data.showTotal)?_c('div',{staticClass:"number"},[_vm._v("在售商品999件")]):_vm._e()]),_c('div',{staticClass:"rightbox"},[(_vm.plugins_data.data.showBtn)?_c('div',{staticClass:"btn"},[_vm._v("进店")]):_vm._e(),(_vm.plugins_data.data.showLocatin)?_c('div',{staticClass:"addressbox"},[_c('i',{staticClass:"icon el-icon-location"}),_c('div',{staticClass:"text"},[_vm._v("距离999km")])]):_vm._e()])],1),(_vm.plugins_data.data.storeStyle==1)?_c('div',{staticClass:"goodsbox"},[_c('div',{staticClass:"notgood"},[_c('el-image',{staticClass:"img",attrs:{"src":require('assets/images/not-good.png'),"fit":"cover"}}),_c('div',{staticClass:"text"},[_vm._v("该店铺暂未上传商品")])],1)]):_vm._e()])]:_vm._e(),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"cardbox",style:({
		'background':_vm.plugins_data.style.background,
		'borderRadius':_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_top/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px '+_vm.plugins_data.style.border_radius_bottom/2+'px',
		'margin-bottom':(index==_vm.list.length?0:_vm.plugins_data.style.margin_bottom/2+'px')
		})},[_c('div',{staticClass:"storebox"},[_c('el-image',{class:{'logo':_vm.plugins_data.data.storeStyle==1,'logo2':_vm.plugins_data.data.storeStyle==2},style:({'border-radius':_vm.plugins_data.data.logoType==1?'50%':'6px'}),attrs:{"src":item.logo,"fit":"cover"}}),_c('div',{staticClass:"infobox",style:(_vm.plugins_data.data.storeStyle==2?'height:60px':'')},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),(_vm.plugins_data.data.showTotal)?_c('div',{staticClass:"number"},[_vm._v("在售商品"+_vm._s(item.goods_count)+"件")]):_vm._e()]),_c('div',{staticClass:"rightbox"},[(_vm.plugins_data.data.showBtn)?_c('div',{staticClass:"btn"},[_vm._v("进店")]):_vm._e(),(_vm.plugins_data.data.showLocatin)?_c('div',{staticClass:"addressbox"},[_c('i',{staticClass:"icon el-icon-location"}),_c('div',{staticClass:"text"},[_vm._v("距离"+_vm._s(_vm.calcDistance(item.distance)))])]):_vm._e()])],1),(_vm.plugins_data.data.storeStyle==1)?_c('div',{staticClass:"goodsbox"},[(item.goods.length==0)?_c('div',{staticClass:"notgood"},[_c('el-image',{staticClass:"img",attrs:{"src":require('assets/images/not-good.png'),"fit":"cover"}}),_c('div',{staticClass:"text"},[_vm._v("该店铺暂未上传商品")])],1):_vm._e(),_vm._l((item.goods),function(goodsitem,i){return _c('div',{key:i,staticClass:"box"},[_c('el-image',{staticClass:"img",attrs:{"src":goodsitem.image,"fit":"cover"}}),_c('div',{staticClass:"price"},[_vm._v("￥"+_vm._s(goodsitem.erp_goods.is_spec?goodsitem.min_price:goodsitem.erp_goods.retail_price))])],1)})],2):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }