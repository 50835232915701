var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"newPeople"}},[_c('div',{staticClass:"newPeoplebox",style:({
			'paddingLeft':_vm.plugins_data.style.box_padding_left/2+'px',
			'paddingRight':_vm.plugins_data.style.box_padding_left/2+'px',
			'paddingTop':_vm.plugins_data.style.box_padding_top/2+'px',
			'paddingBottom':_vm.plugins_data.style.box_padding_bottom/2+'px',
			'marginLeft':_vm.plugins_data.style.box_margin_left/2+'px',
			'marginRight':_vm.plugins_data.style.box_margin_left/2+'px',
			'marginTop':_vm.plugins_data.style.box_margin_top/2+'px',
			'marginBottom':_vm.plugins_data.style.box_margin_bottom/2+'px',
			'background':_vm.plugins_data.style.box_bg_type===1?'rgba(0,0,0,0)':_vm.plugins_data.style.box_background,
			'borderRadius':_vm.plugins_data.style.box_radius_top/2+'px '+_vm.plugins_data.style.box_radius_top/2+'px '+_vm.plugins_data.style.box_radius_bottom/2+'px '+_vm.plugins_data.style.box_radius_bottom/2+'px',
		})},[(_vm.plugins_data.backgroundImg)?_c('img',{staticClass:"backgroundImg",style:({'borderRadius':_vm.plugins_data.style.box_radius_top/2+'px '+_vm.plugins_data.style.box_radius_top/2+'px '+_vm.plugins_data.style.box_radius_bottom/2+'px '+_vm.plugins_data.style.box_radius_bottom/2+'px',
		}),attrs:{"src":_vm.plugins_data.backgroundImg,"alt":""}}):_vm._e(),_c('div',{staticClass:"newPeopleHeadbox",style:({'color':_vm.plugins_data.style.title_color})},[_c('div',{staticClass:"left"}),(_vm.plugins_data.style.title_right)?_c('div',{staticClass:"right"},[_c('div',{staticClass:"text"},[_vm._v("查看更多")]),_c('i',{staticClass:"el-icon-arrow-right icon"})]):_vm._e()]),_c('div',{style:({
			'paddingLeft':_vm.plugins_data.style.content_padding_left/2+'px',
			'paddingRight':_vm.plugins_data.style.content_padding_left/2+'px',
			'background':_vm.plugins_data.style.content_bg_type===1?'rgba(0,0,0,0)':_vm.plugins_data.style.content_background,
			'borderRadius':_vm.plugins_data.style.content_radius_top/2+'px '+_vm.plugins_data.style.content_radius_top/2+'px '+_vm.plugins_data.style.content_radius_bottom/2+'px '+_vm.plugins_data.style.content_radius_bottom/2+'px',
		})},[_c('div',{staticClass:"newPeopleContentbox",style:({
				'paddingTop':_vm.plugins_data.style.content_padding_top/2+'px',
				'paddingBottom':_vm.plugins_data.style.content_padding_top/2+'px',
			})},[(_vm.goods.length==0)?_c('div',{staticClass:"goodsbox",style:({
					'marginRight':_vm.plugins_data.style.content_padding_left/2+'px'
				})},[_c('el-image',{staticClass:"image",attrs:{"src":"","fit":"cover"}}),_c('div',{staticClass:"name"},[_vm._v("请选择商品")]),_c('div',{staticClass:"price"},[_vm._v("￥0.00")])],1):_vm._e(),_vm._l((_vm.goods),function(item,index){return _c('div',{key:index,staticClass:"goodsbox",style:({
					'marginRight':(index+1)<_vm.goods.length?_vm.plugins_data.style.content_padding_left/2+'px':0,
				})},[_c('el-image',{staticClass:"image",attrs:{"src":item.image||'',"alt":"图片失踪了","fit":"cover"}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"price"},[_vm._v("￥"+_vm._s(item.selling_price))])],1)})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }