<template>
	<div class="plugins" :style="{'padding':plugins_data.style.margin/2+'px 0'}">
		<div class="richtext" 
		:style="{
			'padding':plugins_data.style.padding_top/2+'px '+plugins_data.style.padding_left/2+'px '+plugins_data.style.padding_bottom/2+'px',
			
		}">
			<div v-html="plugins_data.data" class="content" :style="{
				'background':plugins_data.style.background,
				'borderRadius':plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_bottom/2+'px '+plugins_data.style.border_radius_bottom/2+'px',
				'padding':plugins_data.style.text_padding/2+'px'
			}"></div>
			<!-- '10px '+ -->
		</div>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
export default {
	name: 'richtext',
	data() {
		return {
			
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	methods:{
		
	}
};
</script>



<style scoped lang="less">
	.content{
		min-height: 80px;
		/deep/ img{
			width: 100% !important;
			height: auto !important;
		}
		/deep/ video{
			width: 100% !important;
			height: auto !important;
		}
	}
</style>
