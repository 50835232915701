<!-- data窗口 -->
<template>
  <div class="plugins">
    <!-- 组件类型设置 -->
    <div>
      <div class="headsty">全局组件</div>
      <div class="box">
        <div class="label">显示类型:</div>
        <el-radio-group v-model="plugins_data.showType" size="small">
          <el-radio-button :label="1">普通</el-radio-button>
          <el-radio-button :label="2">左右滑动</el-radio-button>
        </el-radio-group>
      </div>
      <div class="box">
        <div class="label">列表类型:</div>
        <el-radio-group v-model="plugins_data.style.type" size="small">
          <el-radio-button :label="1">双列</el-radio-button>
          <el-radio-button :label="2">三列</el-radio-button>
          <el-radio-button :label="3" v-if="plugins_data.showType==1">列表</el-radio-button>
          <el-radio-button :label="4">单列</el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <!-- 商品设置 -->
    <div>
      <div class="headsty">添加商品</div>

      <div class="box" style="align-items: flex-start">
        <div class="label">选择商品:</div>
        <div style="flex: 1">
          <el-radio-group v-model="plugins_data.goods_option.type" @change="getClassList()" size="medium">
            <el-radio :label="1">手动选择</el-radio>
            <el-radio :label="2">选择分类</el-radio>
            <el-radio :label="3">选择分组</el-radio>
            <el-radio :label="4" style="margin-top: 10px">营销属性</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="goodtabel" v-if="plugins_data.goods_option.type == 1">
        <div class="tabelstyle tabelhead">
          <div class="goodsimg">商品</div>
          <div class="goodsname"></div>
          <div class="price">价格</div>
          <div class="btnbox">操作</div>
        </div>
        <div class="tabelstyle nodata" v-if="plugins_data.goods == 0">
          暂无数据
        </div>
        <draggable v-model="plugins_data.goods" :options="{ animation: 200 }">
          <div class="tabelstyle gooslist" v-for="(item, index) in plugins_data.goods" :key="index">
            <div class="goodsimg">
              <i class="el-icon-s-operation icon" color="#999999"></i>
              <el-image class="image" :src="item.image" fit="cover"></el-image>
            </div>
            <div class="goodsname">
              <div class="text">{{ item.name }}</div>
            </div>
            <div class="price" v-if="item.is_spec">
              ￥<span>{{ item.min_price }}</span>起
            </div>
            <div class="price" v-else>
              ￥<span>{{ item.selling_price }}</span>
            </div>
            <div class="btnbox">
              <el-button type="text" size="small" @click="toreplace(index)">替换</el-button>
              <el-button type="text" size="small" @click="delgoods(index)">删除</el-button>
            </div>
          </div>
        </draggable>

        <div class="tabelstyle addbtn">
          <span @click="showdialog">+添加商品({{ plugins_data.goods.length }}/50)</span>
        </div>
      </div>
      <!-- 选择分类 -->
      <div class="goodtabel" v-if="plugins_data.goods_option.type == 2">
        <div class="tabelstyle tabelhead">
          <div class="goodsname" style="padding-left: 12px">分类名称</div>
          <div class="btnbox">操作</div>
        </div>
        <div class="tabelstyle nodata" v-if="plugins_data.category == 0">
          暂无数据
        </div>
        <div class="tabelstyle gooslist" v-for="(item, index) in plugins_data.category" :key="index">
          <div class="goodsimg">
            <el-image class="image" :src="item.image" fit="cover"></el-image>
          </div>
          <div class="goodsname">
            <div class="text">{{ item.name }}</div>
          </div>
          <div class="btnbox">
            <el-button type="text" size="small" @click="replaceClass(index)">替换</el-button>
            <el-button type="text" size="small" @click="delClass(index)">删除</el-button>
          </div>
        </div>
        <div class="tabelstyle addbtn" v-if="plugins_data.category == 0">
          <span @click="openGoodsClass">+添加分类</span>
        </div>
      </div>
      <!-- 选择分组 -->
      <div class="goodtabel" v-if="plugins_data.goods_option.type == 3">
        <div class="tabelstyle tabelhead">
          <div class="goodsname" style="padding-left: 12px">分组名称</div>
          <div class="btnbox">操作</div>
        </div>
        <div class="tabelstyle nodata" v-if="plugins_data.group == 0">
          暂无数据
        </div>
        <div class="tabelstyle gooslist" v-for="(item, index) in plugins_data.group" :key="index">
          <div class="goodsname" style="padding-left: 12px">
            <div class="text">{{ item.name }}</div>
          </div>
          <div class="btnbox">
            <el-button type="text" size="small" @click="replaceGroup(index)">替换</el-button>
            <el-button type="text" size="small" @click="delGroup(index)">删除</el-button>
          </div>
        </div>
        <div class="tabelstyle addbtn" v-if="plugins_data.group == 0">
          <span @click="showGroupShow">+添加分组</span>
        </div>
      </div>
      <!-- 营销属性 -->
      <template v-if="plugins_data.goods_option.type == 4">
        <div class="box" style="align-items: flex-start">
          <div class="label"></div>
          <div style="flex: 1; padding: 20px; background: #f4f6f8">
            <el-radio-group v-model="plugins_data.marketing_label" @change="getClassList()" size="medium">
              <el-radio label="新品">新品商品</el-radio>
              <el-radio label="热卖">热卖商品</el-radio>
              <el-radio label="推荐">推荐商品</el-radio>
            </el-radio-group>
          </div>
        </div>
      </template>
      <template v-if="plugins_data.goods_option.type != 1">
        <div class="box">
          <div class="label">显示数量:</div>
          <el-slider class="sliderstyle" v-model="plugins_data.showNumber" :max="200"></el-slider>
          <el-input class="styleinput" type="number" v-model="plugins_data.showNumber">
            <template slot="append">件</template>
          </el-input>
        </div>
        <div class="box" style="align-items: flex-start">
          <div class="label">商品排序:</div>
          <div style="flex: 1">
              <el-radio-group v-model="plugins_data.sort" @change="getClassList()" size="medium">
                <el-radio :label="1">综合</el-radio>
                <el-radio :label="2">按销量</el-radio>
                <el-radio :label="3">价格降序</el-radio>
                <el-radio :label="4">价格升序</el-radio>
              </el-radio-group>
          </div>
        </div>
      </template>
    </div>

    <!-- 组件style设置 -->
    <div>
      <div class="headsty">内容样式</div>

      <div class="box">
        <div class="label">组件背景:</div>
        <el-radio v-model="plugins_data.style.card_bg_type" :label="1">透明</el-radio>
        <el-radio v-model="plugins_data.style.card_bg_type" :label="2">颜色</el-radio>
      </div>
      <div class="box" v-if="plugins_data.style.card_bg_type == 2">
        <div class="label">组件背景颜色:</div>
        <el-color-picker style="margin-right: 20px" size="small" v-model="plugins_data.style.card_background">
        </el-color-picker>
        <el-input v-model="plugins_data.style.card_background" style="width: 100px; margin-right: 6px"></el-input>
        <el-button plain size="small" @click="plugins_data.style.card_background = '#FFFFFF'">重置</el-button>
      </div>
      <div class="box">
        <div class="label">商品组背景:</div>
        <el-color-picker style="margin-right: 20px" size="small" v-model="plugins_data.style.goods_background">
        </el-color-picker>
        <el-input v-model="plugins_data.style.goods_background" style="width: 100px; margin-right: 6px"></el-input>
        <el-button plain size="small" @click="plugins_data.style.goods_background = '#FFFFFF'">重置</el-button>
      </div>
      <div class="linesty"></div>
      <div class="box">
        <div class="label">购买按钮:</div>
        <el-radio v-model="plugins_data.style.paybtn_hidden" :label="true">显示</el-radio>
        <el-radio v-model="plugins_data.style.paybtn_hidden" :label="false">不显示</el-radio>
      </div>
      <div class="box" v-if="plugins_data.style.paybtn_hidden">
        <div class="label">按钮样式:</div>
        <!-- plugins_data.style.paybtn_type -->
        <el-button-group>
          <el-button :class="{'active':plugins_data.style.paybtn_type==1}" size="small" icon="el-icon-car1"
            @click="setPayBtnType(1)"></el-button>
          <el-button :class="{'active':plugins_data.style.paybtn_type==2}" size="small" icon="el-icon-car2"
            @click="setPayBtnType(2)"></el-button>
          <el-button :class="{'active':plugins_data.style.paybtn_type==3}" size="small" icon="el-icon-car3"
            @click="setPayBtnType(3)"></el-button>
          <el-button :class="{'active':plugins_data.style.paybtn_type==4}" size="small" icon="el-icon-car4"
            @click="setPayBtnType(4)"></el-button>
        </el-button-group>
      </div>
      <div class="box" v-if="plugins_data.style.paybtn_hidden">
        <div class="label">按钮颜色:</div>
        <el-color-picker style="margin-right: 20px" size="small" v-model="plugins_data.style.paybtn_bg">
        </el-color-picker>
        <el-input v-model="plugins_data.style.paybtn_bg" style="width: 100px; margin-right: 6px"></el-input>
        <el-button plain size="small" @click="plugins_data.style.paybtn_bg = '#FF0000'">重置</el-button>
      </div>
      <div class="linesty"></div>
      <div class="box">
        <div class="label">上边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.padding_top" :max="50"></el-slider>
        <el-input class="styleinput" type="number" v-model="plugins_data.style.padding_top">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">左右边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.padding_left" :max="50"></el-slider>
        <el-input class="styleinput" type="number" v-model="plugins_data.style.padding_left">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.padding_bottom" :max="50"></el-slider>
        <el-input class="styleinput" type="number" v-model="plugins_data.style.padding_bottom">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">上边角:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="50"></el-slider>
        <el-input class="styleinput" type="number" v-model="plugins_data.style.border_radius_top">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边角:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="50"></el-slider>
        <el-input class="styleinput" type="number" v-model="plugins_data.style.border_radius_bottom">
          <template slot="append">PX</template>
        </el-input>
      </div>
    </div>

    <!-- 添加商品弹窗 -->
    <el-dialog id="stroeaddgoods" title="商品选择器" :visible.sync="addgoodsshow" :modal-append-to-body="false"
      width="960px">
      <div class="goodsbox" v-loading="loading">
        <div class="headbox">
          <el-form :inline="true">
            <el-form-item label="商品名称">
              <el-input v-model="keyword" placeholder="商品名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="goodSearch">查询</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="dialogtabel">
          <el-table v-loading="loading" :data="goodslist" style="width: 100%" stripe
            :header-cell-style="{ background: '#fafafa', color: '#999' }">
            <el-table-column label="商品" width="80">
              <template slot-scope="scope">
                <el-image style="width: 58px; height: 58px" :src="scope.row.image" fit="cover"></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="">
              <template slot-scope="scope">
                <span class="name">{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="价格" width="160" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.is_spec">
                  ￥{{ scope.row.min_price }}起
                </div>
                <div v-else>￥{{ scope.row.selling_price }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="total_stock" label="库存" width="160" align="center"></el-table-column>
            <el-table-column label="操作" width="80" align="center">
              <template slot-scope="scope">
                <div class="choosebtn choose not-select" @click="delchoosegoods(scope.row)"
                  v-if="choose_id.indexOf(scope.row.id) != -1">
                  已选
                </div>
                <div class="choosebtn not-select" v-else @click="choosegoods(scope.row)">
                  选择
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pages" v-if="total > 5">
          <el-pagination background :hide-on-single-page="true" @current-change="goodChangepage" :current-page="page"
            :page-size="5" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addgoodsshow = false">取 消</el-button>
        <el-button type="primary" @click="confirmgoods">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 商品组弹窗 -->
    <el-dialog id="stroeaddgoods" title="商品组选择器" :visible.sync="addGroupShow" :modal-append-to-body="false"
      width="960px">
      <div class="goodsbox" v-loading="loading">
        <div class="headbox">
          <el-form :inline="true">
            <el-form-item label="">
              <el-input v-model="groupKeyword" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="groupSearch">查询</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="dialogtabel">
          <el-table v-loading="loading" :data="groupList" style="width: 100%" stripe
            :header-cell-style="{ background: '#fafafa', color: '#999' }">
            <el-table-column prop="name" label="商品分组"></el-table-column>
            <el-table-column label="操作" width="80" align="center">
              <template slot-scope="scope">
                <div class="choosebtn" :class="{
                    choose:
                      chooseGroup.length > 0
                        ? chooseGroup[0].id == scope.row.id
                        : '',
                  }" @click="selectGroup(scope.row)">
                  {{
                    chooseGroup.length > 0
                      ? chooseGroup[0].id == scope.row.id
                        ? "已选"
                        : "选择"
                      : "选择"
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pages" v-if="groupTotal > 5">
          <el-pagination background :hide-on-single-page="true" @current-change="groupChangepage"
            :current-page="groupPage" :page-size="5" layout="total, prev, pager, next, jumper" :total="groupTotal">
          </el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGroupShow = false">取 消</el-button>
        <el-button type="primary" @click="confirmGroup">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加商品分类 -->
    <el-dialog title="商品分类选择器" :visible.sync="goodsClassificationVisible" :modal-append-to-body="false" width="960px">
      <goodsClass :chooseShow.sync="goodsClassificationVisible" @confirm="goodsClassData"></goodsClass>
    </el-dialog>
  </div>
</template>

<script>
  import {
    eltips
  } from "@/util/util.js";
  import draggable from "vuedraggable";
  import goodsClass from "@/components/goods/goodsClass.vue";
  export default {
    name: "goodsgroup",
    components: {
      draggable,
      goodsClass
    },
    data() {
      return {
        goodsClassificationVisible: false,
        chooseCategory: [],
        chooseCategory_id: [],
        // 商品分组
        groupKeyword: "",
        groupList: [],
        groupTotal: 0,
        addGroupShow: false,
        groupPage: 1,
        addgoodsshow: false,
        goodslist: [],
        choose: [],
        choose_id: [],
        chooseGroup: [],
        chooseGroup_id: [],
        keyword: "",
        page: 1,
        total: 1,
        loading: true,
        replace_type: 1, //1、添加 2、替换
        replace_index: "",
      };
    },
    props: {
      plugins_data: {
        type: Object
      },
    },
    created() {
      this.getgoodslist();
      this.getGroupList();
      this.getClassList();
      this.plugins_data.group.forEach((item) => {
        this.chooseGroup.push(item);
        this.chooseGroup_id.push(item.id);
      });

      this.plugins_data.category.forEach((item) => {
        this.chooseCategory.push(item);
        this.chooseCategory_id.push(item.id);
      });
    },
    methods: {
      //   打开商品分类
      openGoodsClass() {
        this.chooseCategory = [];
        this.chooseCategory_id = [];
        this.plugins_data.category.forEach((item) => {
          this.chooseCategory.push(item);
          this.chooseCategory_id.push(item.id);
        });
        this.goodsClassificationVisible = true;
      },
      //   选择分类
      goodsClassData(ids) {
        this.chooseCategory = [JSON.parse(JSON.stringify(ids.data))];
        this.chooseCategory_id = [JSON.parse(JSON.stringify(ids.data.id))];
        this.plugins_data.category = [JSON.parse(JSON.stringify(ids.data))];
        this.getClassList();
        this.goodsClassificationVisible = false;
      },
      //替换分类
      replaceClass() {
        this.goodsClassificationVisible = true;
      },
      delClass(index) {
        this.plugins_data.category.splice(index, 1);
        this.getClassList();
      },
      getClassCheckedNodes(val) {
        this.treeData = val;
        this.defaultChecked = [];
        val.forEach((o) => {
          this.defaultChecked.push(o.id);
        });
      },
      //替换分组
      replaceGroup() {
        this.replace_type = 2;
        this.addGroupShow = true;
      },
      delGroup(index) {
        this.plugins_data.group.splice(index, 1);
        this.getClassList();
      },
      //   打开分组
      showGroupShow() {
        this.chooseGroup = [];
        this.chooseGroup_id = [];
        this.plugins_data.group.forEach((item) => {
          this.chooseGroup.push(item);
          this.chooseGroup_id.push(item.id);
        });
        this.replace_type = 1;
        this.addGroupShow = true;
      },
      //   选择分组
      confirmGroup() {
        this.plugins_data.group = JSON.parse(JSON.stringify(this.chooseGroup));
        this.getClassList();
        this.addGroupShow = false;
      },
      groupChangepage(e) {
        this.groupPage = e;
        this.getGroupList();
      },
      selectGroup(item) {
        this.chooseGroup = [JSON.parse(JSON.stringify(item))];
        this.chooseGroup_id = [JSON.parse(JSON.stringify(item.id))];
      },
      //查询
      groupSearch() {
        this.groupPage = 1;
        this.getGroupList();
      },
      //获取商品分组
      getGroupList() {
        let data = {
          page: this.groupPage,
          keyword: this.groupKeyword,
          limit: 5,
        };
        this.loading = true;
        this.axios.get("/api/retail/admin/goods/group", {
          params: data
        }).then((res) => {
          this.groupTotal = res.data.total;
          this.loading = false;
          if (res.code == 200) {
            this.groupList = res.data.data;
          }
        });
      },
      choosegoods(item) {
        if (this.replace_type == 1) {
          this.choose.push(item);
          this.choose_id.push(item.id);
        } else {
          this.choose = [item];
          this.choose_id = [item.id];
        }
      },
      delchoosegoods(item) {
        this.choose.splice(this.choose_id.indexOf(item.id), 1);
        this.choose_id.splice(this.choose_id.indexOf(item.id), 1);
      },
      //确认选择
      confirmgoods() {
        if (this.replace_type == 1) {
          this.plugins_data.goods = JSON.parse(JSON.stringify(this.choose));
        } else {
          if (this.plugins_data.goods.length > 1) {
            let item = this.plugins_data.goods[this.replace_index];
            item = this.choose[0];
            this.$set(this.plugins_data.goods, this.replace_index, item);
          } else {
            this.plugins_data.goods = [...this.choose];
          }
        }
        this.plugins_data.list = JSON.parse(
          JSON.stringify(this.plugins_data.goods)
        );
        this.addgoodsshow = false;
      },
      //删除商品
      delgoods(index) {
        this.plugins_data.goods.splice(index, 1);
        this.plugins_data.list.splice(index, 1);
      },
      //替换
      toreplace(index) {
        this.replace_index = index;
        this.choose = [this.plugins_data.goods[index]];
        this.choose_id = [this.plugins_data.goods[index].id];
        this.replace_type = 2;
        this.addgoodsshow = true;
      },
      //添加
      showdialog() {
        this.choose = [];
        this.choose_id = [];
        this.plugins_data.goods.forEach((item) => {
          this.choose.push(item);
          this.choose_id.push(item.id);
        });
        this.replace_type = 1;
        this.addgoodsshow = true;
      },
      //查询
      goodSearch() {
        this.page = 1;
        this.getgoodslist();
      },
      //页码切换事件
      changepage(e) {
        this.page = e;
        this.getgoodslist();
      },
      goodChangepage(e) {
        this.page = e;
        this.getgoodslist();
      },
      // 查询商品列表
      getClassList() {
        let data = {
          page: this.page,
          status: 1,
          limit: 50,
        };
        let type = this.plugins_data.goods_option.type;
        // 1、自选商品 2、分类商品 3、分组商品 4、营销属性
        if (type == 1) {
          if (this.plugins_data.goods.length == 0) {
            this.plugins_data.list = [];
            return;
          }
          data.ids = this.plugins_data.goods.map(item => item.id).join(',')
        }
        if (type == 2) {
          if (this.chooseCategory_id.length == 0) {
            this.plugins_data.list = [];
            return;
          }
          data.classify_id = this.chooseCategory_id[0];
        }
        if (type == 3) {
          if (this.chooseGroup_id.length == 0) {
            this.plugins_data.list = [];
            return;
          }
          data.group_id = this.chooseGroup_id[0];
        }
        if (type == 4) {
          data.marketing_label = this.plugins_data.marketing_label;
        }
        // 	  综合1 按销量2 价格降序3 价格升序4
        if (this.plugins_data.sort == 1) {
          data.sort_field = "";
          data.sort_type = "";
          this.plugins_data.sort_field = ""
          this.plugins_data.sort_type = ""
        }
        if (this.plugins_data.sort == 2) {
          data.sort_field = "1";
          data.sort_type = "";
          this.plugins_data.sort_field = "1"
          this.plugins_data.sort_type = ""
        }
        if (this.plugins_data.sort == 3) {
          data.sort_field = "2";
          data.sort_type = "1";
          this.plugins_data.sort_field = "2"
          this.plugins_data.sort_type = "1"
        }
        if (this.plugins_data.sort == 4) {
          data.sort_field = "2";
          data.sort_type = "2";
          this.plugins_data.sort_field = "2"
          this.plugins_data.sort_type = "2"
        }
        this.axios.get("/api/retail/admin/goods/goods", {
          params: data
        }).then((res) => {
          if (res.code == 200) {
            if (data.ids) {
              let list = res.data.data;
              let ids = data.ids.split(',')
              list.sort((a, b) => {
                let ad = a.id.toString()
                let bd = b.id.toString()
                if (ids.indexOf(ad) < ids.indexOf(bd)) return -1;
                if (ids.indexOf(ad) > ids.indexOf(bd)) return 1;
                return 0;
              })
              this.plugins_data.goods = list;
            } else {
              this.plugins_data.list = res.data.data;
            }
          }
        });
      },
      //获取商品列表
      getgoodslist() {
        let data = {
          page: this.page,
          goods_name: this.keyword,
          status: 1,
          limit: 5,
        };
        this.loading = true;
        this.axios.get("/api/retail/admin/goods/goods", {
          params: data
        }).then((res) => {
          this.total = res.data.total;
          this.loading = false;
          if (res.code == 200) {
            this.goodslist = JSON.parse(JSON.stringify(res.data.data));
          }
        });
      },
      setPayBtnType(type) {
        this.plugins_data.style.paybtn_type = type
      }
    },
  };
</script>

<style>
  #plugins .box .el-input__inner {
    border-radius: 0 !important;
  }

  #stroeaddgoods .el-dialog__header {
    padding: 14px 20px;
    border-bottom: 1px solid #e9edef;
    font-weight: bold;
    font-size: 16;
  }

  #stroeaddgoods .el-dialog__headerbtn {
    top: 14px;
  }

  #stroeaddgoods .el-dialog__body {
    padding: 10px 20px;
    border-bottom: 1px solid #e9edef;
  }

  #stroeaddgoods .el-dialog__footer {
    text-align: center;
  }
</style>
<style scoped lang="less">
  @import "../../style/pluginsCommon.css";

  /deep/.el-radio {
    margin-right: 20px !important;
  }

  .goodtabel {
    border: 1px solid #e9edef;
    margin-bottom: 20px;

    .tabelstyle {
      display: flex;
      align-items: center;
      height: 40px;
      cursor: default;

      .goodsimg {
        width: 20%;
      }

      .goodsname {
        flex: 3;
        width: 40%;
      }

      .price {
        width: 20%;
        text-align: center;
      }

      .btnbox {
        width: 20%;
        text-align: center;
      }
    }

    .tabelhead {
      background: rgb(244, 246, 248);
      font-weight: bold;
      border-bottom: 1px solid #e9edef;

      .goodsimg {
        padding-left: 12px;
      }
    }

    .nodata {
      justify-content: center;
      border-bottom: 1px solid #e9edef;
    }

    .addbtn {
      justify-content: center;
      color: #2d8cf0;

      span {
        cursor: pointer;
      }
    }

    .gooslist {
      border-bottom: 1px solid #e9edef;
      justify-content: center;
      height: 60px;

      .goodsimg {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          margin-right: 10px;
          font-size: 20px;
        }

        .image {
          width: 38px;
          height: 38px;
        }
      }

      .goodsname {
        display: flex;
        align-items: center;

        .icon {
          flex: 0 0 20px;
          font-size: 12px;
          width: 20px;
          line-height: 20px;
          text-align: center;
          margin-right: 6px;
        }

        .xu {
          background-color: #e6fff9;
          color: #00c5c5;
        }

        .shi {
          background-color: #f0faff;
          color: #2d8cf0;
        }

        .text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .price {
        font-size: 13px;
        font-weight: bold;
      }
    }
  }

  .dialogtabel {
    .icon {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      margin-right: 6px;
    }

    .xu {
      background-color: #e6fff9;
      color: #00c5c5;
    }

    .shi {
      background-color: #f0faff;
      color: #2d8cf0;
    }

    .name {
      width: 394px;
      vertical-align: top;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .choosebtn {
      width: 60px;
      line-height: 32px;
      border-radius: 2px;
      color: #2d8cf0;
      border: 1px solid #c3c3c3;
      cursor: pointer;

      &:hover {
        border-color: #2d8cf0;
      }
    }

    .choose {
      background: #2d8cf0;
      color: #ffffff;
      border-color: #2d8cf0;
    }
  }

  .pages {
    text-align: center;
    background: #ffffff;
    padding: 10px 0;
    border-top: 1px solid #ededed;
  }

  .el-button-group {

    .el-button {
      font-size: 24px;
      padding: 4px 10px;

      &.active {
        color: #1989fa;
        border-color: #badcfe;
        background-color: #e8f3ff;
      }
    }
  }
</style>